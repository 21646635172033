import { useParams } from 'react-router-dom'
import { useGetQuery } from '../../../../query'

const useWorkerMap = () => {
  const { id } = useParams<{ id: string }>()

  const { data: worker } = useGetQuery('workers', id)

  return { worker }
}
export default useWorkerMap
