import React from 'react'

import UserCreateView from './UserCreateView'
import useUserCreate from './useUserCreate'

const UserCreate = (): JSX.Element => {
  const state = useUserCreate()

  return <UserCreateView {...state} />
}

export default UserCreate
