/* eslint-disable react/display-name */
import React, { FunctionComponent, HTMLAttributes, ReactText, useContext } from 'react'
import { Table, Tag } from 'antd'

import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'

import ListLayout from '../../layouts/ListLayout'
import { onTableChange } from '../../components/useTable'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import FilterTextSearch from '../../components/FilterTextSearch'
import WorkerDevice from '../../components/workerDevice'
import FilterTags from '../../components/filterTags'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { Group } from '../../providers/ClientProvider/client/services/groups/types'
import { generatePath, Link } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  handleFilter: (value: ReactText, dataIndex: string) => void
  dictionary: any
  t: any
  groups: Group[]
}

export const Columns = (props: ColumnProps): ColumnType<Worker>[] => {
  return [
    {
      title: props.t(props.dictionary.workersPage.firstName),
      dataIndex: 'firstName',
      sorter: true,
      sortOrder: props?.currentSort['firstName'],
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'firstName'
        })
    },
    {
      title: props.t(props.dictionary.workersPage.lastName),
      dataIndex: 'lastName',
      sorter: true,
      sortOrder: props?.currentSort['lastName'],
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'lastName'
        })
    },
    {
      title: props.t(props.dictionary.general.device),
      dataIndex: 'deviceId',
      render: (value: string, record: Worker): JSX.Element | undefined => (
        <WorkerDevice deviceId={record.deviceId} />
      ),
      responsive: ['md']
    },
    {
      title: props.t(props.dictionary.workersPage.groups),
      dataIndex: 'groups',
      render: (value: string[]): JSX.Element[] | undefined => {
        return value?.map((groupId) => {
          const group = props.groups.find((group) => group._id === groupId)
          if (group) {
            return (
              <Link
                to={generatePath(appRoutes.groupShow.path, {
                  id: group._id
                })}
                onClick={(event) => event.stopPropagation()}
              >
                <Tag>{group.name}</Tag>
              </Link>
            )
          }
          return <></>
        })
      },
      responsive: ['lg']
    },
    {
      title: props.t(props.dictionary.workersPage.phoneNumber),
      dataIndex: 'phoneNumber',
      responsive: ['lg']
    },
    {
      title: props.t(props.dictionary.workersPage.score),
      dataIndex: 'score',
      sorter: true,
      sortOrder: props?.currentSort['score'],
      responsive: ['md']
    }
  ]
}

interface WorkersViewProps {
  workers?: Worker[]
  loading: boolean
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Worker>
  handleOnRowClick: (id: string) => void
  onCreateNewWorker: () => void
  handleFilter: (value: ReactText, dataIndex: string) => void
  groups: Group[]
}

const WorkersView: FunctionComponent<WorkersViewProps> = (props: WorkersViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const workersPage = dictionary.workersPage

  return (
    <ListLayout
      title={t(dictionary.layouts.workers)}
      buttonAction={props.onCreateNewWorker}
      buttonText={t(workersPage.addWorker)}
    >
      <FilterTags />
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns({
          currentSort: props.currentSort,
          handleFilter: props.handleFilter,
          t: t,
          dictionary: dictionary,
          groups: props.groups
        })}
        loading={props.loading}
        dataSource={props.workers}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleOnRowClick(record._id)
          }
        }}
      />
    </ListLayout>
  )
}

export default WorkersView
