import { useContext, useEffect } from 'react'
import { mainRoutes } from '../../containers/Router/routes'
import { message } from 'antd'
import ClientContext from '../../providers/ClientProvider/client'
import config from '../../config'
import { useHistory } from 'react-router-dom'
import AppContext from '../../layouts/AppLayout/context/AppContext'
import { ActionTypes } from '../../layouts/actionTypes.enum'

const useLogOut = (): void => {
  const client = useContext(ClientContext)
  const { dispatch } = useContext(AppContext)

  const history = useHistory()

  useEffect(() => {
    async function logOut(): Promise<void> {
      try {
        await client.logout()
        window.location.href = mainRoutes.login.path

        history.push(mainRoutes.login.path)
        dispatch({
          type: ActionTypes.SET,
          payload: {
            userId: undefined
          }
        })
      } catch (error) {
        console.log('error', error)
        // the 404 error may happen if the user was removed and so the current client can not log in
        if (error && error.code === 404) {
          window.localStorage.removeItem(config.jwtStorageKey)
          window.location.reload()
        } else {
          message.error(error.message)
        }
      }
    }

    logOut()
  }, [])
}

export default useLogOut
