import React, { FunctionComponent, HTMLAttributes, useContext } from 'react'
import { Table } from 'antd'
import { format } from 'date-fns'

import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'

import ListLayout from '../../layouts/ListLayout'
import { onTableChange } from '../../components/useTable'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { Group } from '../../providers/ClientProvider/client/services/groups/types'

export const Columns = (t: any, groupPage: any): ColumnType<Group>[] => {
  return [
    {
      title: t(groupPage.name),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t(groupPage.numberOfWorkers),
      dataIndex: 'assignedWorkersIds',
      sorter: true,
      render: (value: string[]): string => value.length.toString()
    },
    {
      title: t(groupPage.createDate),
      dataIndex: 'createdAt',
      sorter: true,
      render: (value: any, record): string =>
        record.createdAt && format(new Date(record.createdAt), 'yyyy.MM.dd ')
    }
  ]
}

interface GroupsViewProps {
  groups?: Group[]
  loading: boolean
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Group>
  handleOnRowClick: (id: string) => void
  onCreateNewGroup: () => void
}

const GroupsView: FunctionComponent<GroupsViewProps> = (props: GroupsViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const groupsPage = dictionary.groupsPage

  return (
    <ListLayout
      title={t(dictionary.layouts.groups)}
      buttonAction={props.onCreateNewGroup}
      buttonText={t(groupsPage.addGroup)}
    >
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns(t, groupsPage)}
        loading={props.loading}
        dataSource={props.groups}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleOnRowClick(record._id)
          }
        }}
      />
    </ListLayout>
  )
}

export default GroupsView
