import React from 'react'

import JobCreateView from './JobCreateView'
import useJobCreate from './useJobCreate'

const JobCreate = (): JSX.Element => {
  const state = useJobCreate()

  return <JobCreateView {...state} />
}

export default JobCreate
