/* eslint-disable react/display-name */
import { Button, Select, Space } from 'antd'
import React, { ReactText } from 'react'
import startCase from 'lodash/startCase'
import { SearchOutlined } from '@ant-design/icons'

export type handleColumnsFilter = (
  values: string[] & { [k: string]: string }[],
  dataIndex: string
) => void

export type selectOption = { label: string; value: string }
export type selectOptions = selectOption[]

export const getSelectableFilterColumn = (
  dataIndex: string,
  handleFilter: (value: ReactText, dataIndex: string) => void,
  selectOptions: selectOptions
) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys }: any): JSX.Element => (
    <div style={{ padding: 8 }}>
      <Space>
        <Select onChange={setSelectedKeys} style={{ height: '30px' }} allowClear={true}>
          {selectOptions.map((option: selectOption, index: number) => (
            <Select.Option key={`${option}${index}`} value={option.value}>
              {startCase(option.label)}
            </Select.Option>
          ))}
        </Select>
        <Button
          type='primary'
          onClick={() => handleFilter(selectedKeys, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 'auto', height: '30px' }}
        >
          Search
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: any): JSX.Element => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  )
})
