import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import './userCreate.less'
import SubpageLayout from '../../../layouts/SubpageLayout'
import { LocalizationContext } from '../../../providers/LocalizationProvider'

interface UserCreateViewProps {
  form: FormInstance
  onFinish: (v: any) => void
  createLoading: boolean
}

const UserCreateView: FunctionComponent<UserCreateViewProps> = (props: UserCreateViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const usersPage = dictionary.usersPage

  return (
    <div className='create-user'>
      <SubpageLayout title={t(usersPage.addUser)} page={t(dictionary.layouts.users)}>
        <Form
          form={props.form}
          name='create-user'
          onFinish={props.onFinish}
          layout='vertical'
          className='app-layout__form'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item
                name='email'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label='Email'
              >
                <Input placeholder='Email' type='email' />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(usersPage.password)}
              >
                <Input placeholder={t(usersPage.password)} type='password' />
              </Form.Item>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              className='app-layout__form-button'
              loading={props.createLoading}
            >
              {t(usersPage.createUser)}
            </Button>
          </Row>
        </Form>
      </SubpageLayout>
    </div>
  )
}

export default UserCreateView
