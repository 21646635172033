import React, { FunctionComponent } from 'react'

import GroupShowLayoutView from './GroupShowLayoutView'
import useGroupShowLayout from './useGroupShowLayout'

interface TaskShowLayoutProps {
  children?: React.ReactNode
}

const GroupShowLayout: FunctionComponent<TaskShowLayoutProps> = (props: TaskShowLayoutProps) => {
  const listLayoutState = useGroupShowLayout()

  return <GroupShowLayoutView {...props} {...listLayoutState} />
}

export default GroupShowLayout
