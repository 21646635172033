import qs from 'qs'
import useTable from '../../components/useTable'
import { useFindQuery } from '../../query'
import { ReactText, useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'

const useDevices = () => {
  const { query, onTableChange, currentSort, currentPage } = useTable<Device>()

  const history = useHistory()

  const { data: devicesRes, status } = useFindQuery('devices', query)

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(appRoutes.deviceEdit.path, {
        id: id
      })
    )
  }, [])

  const onCreateNewDevice = useCallback((): void => {
    history.push(appRoutes.deviceCreate.path)
  }, [])

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = { $regex: value, $options: 'ix' }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  return {
    devices: devicesRes?.data,
    loading: status === 'loading',
    total: devicesRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleOnRowClick,
    onCreateNewDevice,
    handleFilter
  }
}
export default useDevices
