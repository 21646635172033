import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Popconfirm, Row, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { format } from 'date-fns'

import './locationDetails.less'

import ContentLoader from '../../../../components/ContentLoader'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'

interface LocationDetailsViewProps {
  location?: Location
  form: FormInstance
  onFinish: (v: any) => void
  patchLoading: boolean
  onDelete: () => void
  deleteLoading: boolean
}

const LocationDetailsView: FunctionComponent<LocationDetailsViewProps> = (
  props: LocationDetailsViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const locationsPage = dictionary.locationsPage

  return (
    <div className='edit-location'>
      <ContentLoader loading={!props.location}>
        <Form
          form={props.form}
          name='edit-location'
          onFinish={props.onFinish}
          initialValues={props.location}
          className='app-layout__form'
          layout='vertical'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(locationsPage.name)}
              >
                <Input placeholder={t(locationsPage.name)} type='text' />
              </Form.Item>
              <Form.Item label={t(locationsPage.createDate)} shouldUpdate>
                {({ getFieldValue }): string => {
                  const value = getFieldValue('createdAt')
                  return value && format(new Date(value), 'yyyy.MM.dd HH:mm')
                }}
              </Form.Item>
              <Form.Item label={t(locationsPage.updateDate)} shouldUpdate>
                {({ getFieldValue }): string => {
                  const value = getFieldValue('updatedAt')
                  return value && format(new Date(value), 'yyyy.MM.dd HH:mm')
                }}
              </Form.Item>
              <Form.Item name='active' label={t(locationsPage.active)} valuePropName='checked'>
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              loading={props.patchLoading}
              className='app-layout__form-button'
            >
              {t(dictionary.general.save)}
            </Button>
            <Popconfirm
              placement='left'
              title={t(locationsPage.deleteConfirmation)}
              okText={t(dictionary.general.yes)}
              cancelText={t(dictionary.general.cancel)}
              onConfirm={props.onDelete}
            >
              <Button danger loading={props.deleteLoading} className='app-layout__form-button'>
                {t(dictionary.general.delete)}
              </Button>
            </Popconfirm>
          </Row>
        </Form>
      </ContentLoader>
    </div>
  )
}

export default LocationDetailsView
