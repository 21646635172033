import { Form, message } from 'antd'
import { useCallback, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../containers/Router/routes'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { useDeleteMutation, useGetQuery, usePatchMutation } from '../../../query'

const useUserEdit = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const usersPage = dictionary.usersPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const { data: user, status } = useGetQuery('users', id)

  const [patchUser, { status: patchStatus }] = usePatchMutation('users')

  const [deleteUser, { status: deleteStatus }] = useDeleteMutation('users')

  const onFinish = useCallback(
    async (values) => {
      if (!id) {
        throw new Error(t(usersPage.incorrectId))
      }

      try {
        await patchUser({
          id,
          data: values
        })

        message.success(t(usersPage.updated))
      } catch (e) {
        console.log(e)
      }
    },
    [id]
  )

  const onDelete = useCallback(async () => {
    if (!id) {
      throw new Error(t(usersPage.incorrectId))
    }

    try {
      await deleteUser(id)

      history.push(appRoutes.users.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  return {
    user,
    form,
    onFinish,
    patchLoading: patchStatus === 'loading',
    onDelete,
    deleteLoading: deleteStatus === 'loading'
  }
}
export default useUserEdit
