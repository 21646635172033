import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import './deviceCreate.less'
import SubpageLayout from '../../../layouts/SubpageLayout'
import { LocalizationContext } from '../../../providers/LocalizationProvider'

interface DeviceCreateViewProps {
  form: FormInstance
  onFinish: (v: any) => void
  createLoading: boolean
}

const DeviceCreateView: FunctionComponent<DeviceCreateViewProps> = (
  props: DeviceCreateViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const devicesPage = dictionary.devicesPage

  return (
    <div className='create-device'>
      <SubpageLayout title={t(devicesPage.addDevice)} page={t(dictionary.layouts.devices)}>
        <Form
          form={props.form}
          name='create-device'
          onFinish={props.onFinish}
          layout='vertical'
          className='app-layout__form'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item
                name='imei'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label='IMEI'
              >
                <Input placeholder='IMEI' type='number' />
              </Form.Item>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              className='app-layout__form-button'
              loading={props.createLoading}
            >
              Stwórz Urządzenie
            </Button>
          </Row>
        </Form>
      </SubpageLayout>
    </div>
  )
}

export default DeviceCreateView
