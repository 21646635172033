import React from 'react'

import TaskMapView from './LocationMapView'
import useTaskMap from './useLocationMap'

const TaskMap = (): JSX.Element => {
  const state = useTaskMap()

  return <TaskMapView {...state} />
}

export default TaskMap
