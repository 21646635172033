import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Popconfirm, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import moment from 'moment'

import './deviceEdit.less'

import ContentLoader from '../../../components/ContentLoader'
import { Device } from '../../../providers/ClientProvider/client/services/devices/types'
import SubpageLayout from '../../../layouts/SubpageLayout'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { ThunderboltOutlined, WifiOutlined } from '@ant-design/icons'
import Map from '../../../components/map'
import { coordinates } from '../../../components/map/mapType'

interface DeviceEditViewProps {
  device?: Device
  form: FormInstance
  onFinish: (v: any) => void
  patchLoading: boolean
  onDelete: () => void
  deleteLoading: boolean
  firstPointLatLang?: coordinates
}

const DeviceEditView: FunctionComponent<DeviceEditViewProps> = (props: DeviceEditViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const devicesPage = dictionary.devicesPage

  return (
    <div className='edit-device'>
      <SubpageLayout title={t(devicesPage.deviceDetails)} page={t(dictionary.layouts.devices)}>
        <ContentLoader loading={!props.device}>
          <Form
            form={props.form}
            name='edit-device'
            onFinish={props.onFinish}
            initialValues={props.device}
            className='app-layout__form'
            layout='horizontal'
          >
            <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
              <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                <Form.Item label={'IMEI'}>{props.device?.imei}</Form.Item>
                <Form.Item label={'GSM'}>
                  {props.device?.lastUpdate ? (
                    <>
                      <WifiOutlined /> {props.device?.gsm} %
                    </>
                  ) : (
                    <>N/A</>
                  )}
                </Form.Item>
                <Form.Item label={t(devicesPage.battery)}>
                  {props.device?.lastUpdate ? (
                    <>
                      <ThunderboltOutlined /> {props.device?.battery} %
                    </>
                  ) : (
                    <>N/A</>
                  )}
                </Form.Item>
                <Form.Item label='GPS'>
                  {props.device?.gps ? <>{props.device?.gps}</> : <>N/A</>}
                </Form.Item>
                <Form.Item label={t(devicesPage.lastUpdate)}>
                  {props.device?.lastUpdate ? (
                    <>{moment(props.device?.lastUpdate).format('HH:mm D-M-YYYY')}</>
                  ) : (
                    <>N/A</>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  margin: 'auto'
                }}
              >
                <Map geoData={props.device?.geoData} centeredLatLng={props.firstPointLatLang} />
              </div>
            </Row>
            <Row className='app-layout__last-row'>
              <Button
                type='primary'
                htmlType='submit'
                loading={props.patchLoading}
                className='app-layout__form-button'
              >
                {t(dictionary.general.save)}
              </Button>
              <Popconfirm
                placement='left'
                title={t(devicesPage.deleteConfirmation)}
                okText={t(dictionary.general.yes)}
                cancelText={t(dictionary.general.cancel)}
                onConfirm={props.onDelete}
              >
                <Button danger loading={props.deleteLoading} className='app-layout__form-button'>
                  {t(dictionary.general.delete)}
                </Button>
              </Popconfirm>
            </Row>
          </Form>
        </ContentLoader>
      </SubpageLayout>
    </div>
  )
}

export default DeviceEditView
