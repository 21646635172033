import { ActionTypes } from '../../actionTypes.enum'

export type MainActions = { type: ActionTypes; payload: Partial<MainState> }

export type MainState = {
  userId?: string
}

export const mainInitialState: MainState = {}

export const mainReducer = (state: MainState, action: MainActions): MainState => {
  switch (action.type) {
    case ActionTypes.SET:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
