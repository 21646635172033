/* eslint-disable react/display-name */
import { Button, Col, Row, Select, Spin, Table, Typography } from 'antd'
import { ColumnType } from 'antd/lib/table/interface'
import React, { FunctionComponent, useContext } from 'react'
import WorkersInJob from '../../../../components/workersInJob'
import { Group } from '../../../../providers/ClientProvider/client/services/groups/types'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'
import { WorkersToAssign } from '../../../../providers/ClientProvider/client/services/tasks/types'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useFindQuery, useGetQuery } from '../../../../query'

import './jobWorkers.less'

const GroupName = (props: { groupId: string }): JSX.Element => {
  const { data: group, status } = useGetQuery('groups', props.groupId)

  if (status === 'loading') {
    return <Spin size='small' />
  }

  return <Typography.Text>{group?.name}</Typography.Text> || <></>
}

const NumberOfAssignedWorkers = (props: { groupId: string; jobId?: string }): JSX.Element => {
  const { data: jobWorkersRes, status } = useFindQuery('jobWorkers', {
    $limit: 0,
    jobId: props.jobId,
    groupId: props.groupId,
    active: true
  })

  if (status === 'loading') {
    return <Spin size='small' />
  }

  return <>{jobWorkersRes?.total}</>
}

interface ColumnProps {
  dictionary: any
  t: any
  jobId?: string
}

export const Columns = (props: ColumnProps): ColumnType<WorkersToAssign>[] => {
  return [
    {
      title: props.t(props.dictionary.jobsPage.group),
      dataIndex: 'groupId',
      render: (value: string): JSX.Element => <GroupName groupId={value} />
    },
    {
      title: props.t(props.dictionary.jobsPage.requiredWorkers),
      dataIndex: 'numberOfWorkers'
    },
    {
      title: props.t(props.dictionary.jobsPage.assignedWorkers),
      render: (record: WorkersToAssign): JSX.Element => (
        <NumberOfAssignedWorkers groupId={record.groupId} jobId={props.jobId} />
      )
    }
  ]
}

interface JobWorkersViewProps {
  job?: Job
  groups?: Group[]
  selectedGroup: string | undefined
  selectedWorker: string | undefined
  setSelectedGroup: (value: string) => void
  setWorkerSearch: (value: string) => void
  workers?: Worker[]
  onAddWorker: () => void
  createJobWorkerLoading: boolean
  setSelectedWorker: (value: string) => void
}

const JobWorkersView: FunctionComponent<JobWorkersViewProps> = (props: JobWorkersViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  return (
    <div className='job-workers'>
      <div className='job-workers__container'>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={6} xl={4}>
            <Select
              className='job-workers__select'
              showArrow
              placeholder={t(jobsPage.chooseGroup)}
              value={props.selectedGroup}
              onSelect={(value): void => props.setSelectedGroup(value.toString())}
            >
              {props.groups?.map((group) => {
                return (
                  <Select.Option key={group._id} value={group._id}>
                    {group.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={4}>
            <Select
              className='job-workers__select'
              showArrow
              placeholder={t(jobsPage.chooseWorker)}
              disabled={!props.selectedGroup}
              showSearch
              filterOption={false}
              value={props.selectedWorker}
              onSelect={(value): void => props.setSelectedWorker(value.toString())}
              onSearch={props.setWorkerSearch}
            >
              {props.workers?.map((worker) => {
                return (
                  <Select.Option
                    key={worker._id}
                    value={worker._id}
                    filter={`${worker.firstName} ${worker.lastName}`}
                  >
                    {worker.firstName} {worker.lastName}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
            <Button onClick={props.onAddWorker} loading={props.createJobWorkerLoading}>
              {t(dictionary.general.add)}
            </Button>
          </Col>
        </Row>
        <Table
          columns={Columns({
            t,
            dictionary,
            jobId: props.job?._id
          })}
          rowKey='_id'
          dataSource={props.job?.workersToAssign}
          expandable={{
            expandedRowRender: (record: WorkersToAssign): JSX.Element => (
              <WorkersInJob workersToAssign={record} jobId={props.job?._id} />
            )
          }}
        />
      </div>
    </div>
  )
}

export default JobWorkersView
