import { ActionTypes } from '../../actionTypes.enum'

export type AppActions = { type: ActionTypes; payload: Partial<AppState> }

export type AppState = {
  leftMenuCollapsed: boolean
  leftMenuDrawerOpen: boolean
  authenticationInProgress: boolean
  userId?: string
}

export const appInitialState: AppState = {
  leftMenuCollapsed: false,
  leftMenuDrawerOpen: false,
  authenticationInProgress: true
}

export const appReducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case ActionTypes.SET:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
