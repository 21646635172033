import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { AppActions, AppState } from './context/mainReducer'
import ClientContext from '../../providers/ClientProvider/client'
import { ActionTypes } from '../actionTypes.enum'
import { appRoutes } from '../../containers/Router/routes'

interface Params {
  state: AppState
  dispatch: React.Dispatch<AppActions>
}

const useReAuthenticate = (params: Params): void => {
  const { dispatch } = params

  const history = useHistory()

  const client = useContext(ClientContext)

  useEffect(() => {
    async function reAuthenticate() {
      try {
        // IMPORTANT DO NOT setAuthenticationInProgress to true!!!
        // this will cause unexpected component remounting

        const authentication = await client.reAuthenticate(true)

        dispatch({
          type: ActionTypes.SET,
          payload: {
            userId: authentication.user._id
          }
        })
      } catch (e) {
        history.push(appRoutes.logout.path)
      } finally {
        dispatch({
          type: ActionTypes.SET,
          payload: {
            authenticationInProgress: false
          }
        })
      }
    }

    reAuthenticate()
  }, [history.location.pathname, dispatch])
}

export default useReAuthenticate
