import React, { FunctionComponent } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'

interface DeviceWorkerViewProps {
  worker?: Worker
}

const DeviceWorkerView: FunctionComponent<DeviceWorkerViewProps> = (
  props: DeviceWorkerViewProps
) => {
  return (
    <>
      {props.worker && (
        <Link
          to={generatePath(appRoutes.workerShow.routes.details.path, {
            id: props.worker._id
          })}
          onClick={(event) => event.stopPropagation()}
        >
          {props.worker.firstName} {props.worker.lastName}
        </Link>
      )}
    </>
  )
}

export default DeviceWorkerView
