import AppLayout from '../../layouts/AppLayout'
import MainLayout from '../../layouts/MainLayout'
import WorkerShowLayout from '../../layouts/WorkerShowLayout'

import Home from '../../pages/Home'
import Login from '../../pages/Login'
import LogOut from '../../pages/LogOut'
import NoMatch from '../../pages/NoMatch'
import Users from '../../pages/Users'
import UserEdit from '../../pages/Users/UserEdit'
import UserCreate from '../../pages/Users/UserCreate'
import Workers from '../../pages/Workers'
import WorkerCreate from '../../pages/Workers/WorkerCreate'
import WorkerData from '../../pages/Workers/WorkerShow/WorkerData'
import WorkerDetails from '../../pages/Workers/WorkerShow/WorkerDetails'
import WorkerMap from '../../pages/Workers/WorkerShow/WorkerMap'
import Devices from '../../pages/Devices'
import DeviceCreate from '../../pages/Devices/DeviceCreate'
import DeviceEdit from '../../pages/Devices/DeviceEdit'
import Locations from '../../pages/Locations'
import LocationCreate from '../../pages/Locations/LocationsCreate'
import LocationDetails from '../../pages/Locations/LocationsShow/LocationDetails'
import LocationMap from '../../pages/Locations/LocationsShow/LocationMap'
import LocationShowLayout from '../../layouts/LocationShowLayout'
import Tasks from '../../pages/Tasks'
import TasksCreate from '../../pages/Tasks/TasksCreate'
import TaskShowLayout from '../../layouts/TaskShowLayout'
import TaskMap from '../../pages/Tasks/TaskShow/TaskMap'
import TaskDetails from '../../pages/Tasks/TaskShow/TaskDetails'
import TaskLocations from '../../pages/Tasks/TaskShow/TaskLocations'
import Groups from '../../pages/Groups'
import GroupCreate from '../../pages/Groups/GroupCreate'
import GroupDetails from '../../pages/Groups/GroupShow/GroupDetails'
import GroupShowLayout from '../../layouts/GroupShowLayout'
import GroupWorkers from '../../pages/Groups/GroupShow/GroupWorkers'
import Jobs from '../../pages/Jobs'
import JobCreate from '../../pages/Jobs/JobCreate'
import JobDetails from '../../pages/Jobs/JobShow/JobDetails'
import JobWorkers from '../../pages/Jobs/JobShow/JobWorkers'
import JobLocations from '../../pages/Jobs/JobShow/JobLocations'
import JobMap from '../../pages/Jobs/JobShow/JobMap'
import JobShowLayout from '../../layouts/JobShowLayout'

export interface Route<T = React.FunctionComponent> {
  path: string
  order: number
  component: T // could not work out a good way of managing this
}

export interface NestedRoute<T = {}> {
  path: string
  order: number
  routes: T
  layout: any
}

export interface Login extends Record<string, Route> {}

export interface AppRoutes
  extends Record<
    string,
    | Route
    | NestedRoute<WorkerShowRoutes>
    | NestedRoute<LocationShowRoutes>
    | NestedRoute<TaskShowRoutes>
    | NestedRoute<GroupShowRoutes>
    | NestedRoute<JobShowRoutes>
  > {
  home: Route
  logout: Route
  users: Route
  userEdit: Route
  userCreate: Route
  workers: Route
  workerCreate: Route
  workerShow: NestedRoute<WorkerShowRoutes>
  devices: Route
  deviceCreate: Route
  deviceEdit: Route
  locations: Route
  locationCreate: Route
  locationShow: NestedRoute<LocationShowRoutes>
  tasks: Route
  taskCreate: Route
  taskShow: NestedRoute<TaskShowRoutes>
  groups: Route
  groupCreate: Route
  groupShow: NestedRoute<GroupShowRoutes>
  jobs: Route
  jobCreate: Route
  jobShow: NestedRoute<JobShowRoutes>
}

export interface WorkerShowRoutes {
  data: Route
  details: Route
  map: Route
}

export interface LocationShowRoutes {
  details: Route
  map: Route
}

export interface TaskShowRoutes {
  details: Route
  locations: Route
  map: Route
}

export interface GroupShowRoutes {
  details: Route
  workers: Route
}

export interface JobShowRoutes {
  details: Route
  workers: Route
  locations: Route
  map: Route
}

interface MainRoutes extends Record<string, Route | NestedRoute<Login> | NestedRoute<AppRoutes>> {
  login: Route
  app: NestedRoute<AppRoutes>
}

const routes: NestedRoute<MainRoutes> = {
  path: '/',
  order: 1,
  layout: MainLayout,
  routes: {
    app: {
      path: '/app',
      order: 1,
      layout: AppLayout,
      routes: {
        home: {
          path: '/app',
          order: 1,
          component: Home
        },
        logout: {
          path: '/app/logout',
          order: 2,
          component: LogOut
        },
        users: {
          path: '/app/users',
          order: 3,
          component: Users
        },
        userEdit: {
          path: '/app/users/:id',
          order: 4,
          component: UserEdit
        },
        userCreate: {
          path: '/app/users-create',
          order: 5,
          component: UserCreate
        },
        workers: {
          path: '/app/workers',
          order: 6,
          component: Workers
        },
        workerShow: {
          path: '/app/workers/:id',
          order: 7,
          layout: WorkerShowLayout,
          routes: {
            details: {
              path: '/app/workers/:id/details',
              order: 1,
              component: WorkerDetails
            },
            data: {
              path: '/app/workers/:id/data',
              order: 2,
              component: WorkerData
            },
            map: {
              path: '/app/workers/:id/map',
              order: 3,
              component: WorkerMap
            }
          }
        },
        workerCreate: {
          path: '/app/workers-create',
          order: 8,
          component: WorkerCreate
        },
        devices: {
          path: '/app/devices',
          order: 9,
          component: Devices
        },
        deviceEdit: {
          path: '/app/devices/:id',
          order: 10,
          component: DeviceEdit
        },
        deviceCreate: {
          path: '/app/device/create',
          order: 11,
          component: DeviceCreate
        },
        locations: {
          path: '/app/locations',
          order: 12,
          component: Locations
        },
        locationCreate: {
          path: '/app/locations/create',
          order: 13,
          component: LocationCreate
        },
        locationShow: {
          path: '/app/locations/:id',
          order: 14,
          layout: LocationShowLayout,
          routes: {
            details: {
              path: '/app/locations/:id/details',
              order: 1,
              component: LocationDetails
            },
            map: {
              path: '/app/locations/:id/map',
              order: 2,
              component: LocationMap
            }
          }
        },
        tasks: {
          path: '/app/tasks',
          order: 15,
          component: Tasks
        },
        taskCreate: {
          path: '/app/tasks/create',
          order: 13,
          component: TasksCreate
        },
        taskShow: {
          path: '/app/tasks/:id',
          order: 14,
          layout: TaskShowLayout,
          routes: {
            details: {
              path: '/app/tasks/:id/details',
              order: 1,
              component: TaskDetails
            },
            locations: {
              path: '/app/tasks/:id/locations',
              order: 2,
              component: TaskLocations
            },
            map: {
              path: '/app/tasks/:id/map',
              order: 3,
              component: TaskMap
            }
          }
        },
        noMatch: {
          path: '*',
          order: 9999, // IMPORTANT: needs to be last in the routes to not override other
          component: NoMatch
        },
        groups: {
          path: '/app/groups',
          order: 15,
          component: Groups
        },
        groupCreate: {
          path: '/app/groups/create',
          order: 16,
          component: GroupCreate
        },
        groupShow: {
          path: '/app/groups/:id',
          order: 17,
          layout: GroupShowLayout,
          routes: {
            details: {
              path: '/app/groups/:id/details',
              order: 1,
              component: GroupDetails
            },
            workers: {
              path: '/app/groups/:id/workers',
              order: 2,
              component: GroupWorkers
            }
          }
        },
        jobs: {
          path: '/app/jobs',
          order: 18,
          component: Jobs
        },
        jobCreate: {
          path: '/app/jobs/create',
          order: 19,
          component: JobCreate
        },
        jobShow: {
          path: '/app/jobs/:id',
          order: 20,
          layout: JobShowLayout,
          routes: {
            details: {
              path: '/app/jobs/:id/details',
              order: 1,
              component: JobDetails
            },
            workers: {
              path: '/app/jobs/:id/workers',
              order: 2,
              component: JobWorkers
            },
            locations: {
              path: '/app/jobs/:id/locations',
              order: 3,
              component: JobLocations
            },
            map: {
              path: '/app/jobs/:id/map',
              order: 4,
              component: JobMap
            }
          }
        }
      }
    },
    login: {
      path: '/',
      order: 1,
      component: Login
    },
    noMatch: {
      path: '*',
      order: 9999, // IMPORTANT: needs to be last in the routes to not override other
      component: NoMatch
    }
  }
}

const mainRoutes = routes.routes
const appRoutes = routes.routes.app.routes
const workerShowRoutes = appRoutes.workerShow.routes
const taskShowRoutes = appRoutes.taskShow.routes
const groupShowRoutes = appRoutes.groupShow.routes
const jobShowRoutes = appRoutes.jobShow.routes

export { mainRoutes, appRoutes, workerShowRoutes, taskShowRoutes, groupShowRoutes, jobShowRoutes }
export default routes
