import React, { FunctionComponent, HTMLAttributes, useContext } from 'react'
import { Table } from 'antd'
import { format } from 'date-fns'

import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'

import ListLayout from '../../layouts/ListLayout'
import { onTableChange } from '../../components/useTable'
import { User } from '../../providers/ClientProvider/client/services/users/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'

export const Columns = (t: any, usersPage: any): ColumnType<User>[] => {
  return [
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t(usersPage.createDate),
      dataIndex: 'createdAt',
      sorter: true,
      render: (value: any, record): string =>
        record.createdAt && format(new Date(record.createdAt), 'yyyy.MM.dd ')
    }
  ]
}

interface UsersViewProps {
  users?: User[]
  loading: boolean
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<User>
  handleOnRowClick: (id: string) => void
  onCreateNewUser: () => void
}

const UsersView: FunctionComponent<UsersViewProps> = (props: UsersViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const usersPage = dictionary.usersPage

  return (
    <ListLayout
      title={t(dictionary.layouts.users)}
      buttonAction={props.onCreateNewUser}
      buttonText={t(usersPage.addUser)}
    >
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns(t, usersPage)}
        loading={props.loading}
        dataSource={props.users}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleOnRowClick(record._id)
          }
        }}
      />
    </ListLayout>
  )
}

export default UsersView
