import { useCallback, useEffect, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { LocationListener } from 'history'
import { AppActions, AppState } from './context/mainReducer'
import { ActionTypes } from '../actionTypes.enum'

interface Params {
  state: AppState
  dispatch: React.Dispatch<AppActions>
}

const useAppLayout = (params: Params) => {
  const { dispatch, state } = params

  const history = useHistory()
  const [leftMenuSelectedKey, setLeftMenuSelectedKey] = useState<string>()

  useEffect(() => {
    const handleSelectedKey: LocationListener = (location) => {
      const leftMenuSelectedKey = Object.entries(appRoutes)
        .map(([key, value]) => value)
        .find((route) =>
          matchPath(location.pathname, {
            path: route.path,
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            exact: !route.routes,
            strict: false
          })
        )?.path

      setLeftMenuSelectedKey(leftMenuSelectedKey)
    }

    // run the check on app start
    handleSelectedKey(history.location, 'PUSH')

    return history.listen(handleSelectedKey)
  }, [])

  const handleOnCollapse = useCallback(
    (collapse) => {
      dispatch({
        type: ActionTypes.SET,
        payload: {
          leftMenuCollapsed: typeof collapse === 'boolean' ? collapse : !state.leftMenuCollapsed
        }
      })
    },
    [state.leftMenuCollapsed]
  )

  const handleDrawerOpen = useCallback(
    (close) => {
      dispatch({
        type: ActionTypes.SET,
        payload: {
          leftMenuDrawerOpen: typeof close === 'boolean' ? !close : !state.leftMenuDrawerOpen
        }
      })
    },
    [state.leftMenuDrawerOpen]
  )

  return {
    leftMenuSelectedKey,
    handleOnCollapse,
    handleDrawerOpen
  }
}

export default useAppLayout
