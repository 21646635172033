import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FunctionComponent } from 'react'

import './backButton.less'

interface BackButtonViewProps {
  onBack: () => void
}

const BackButtonView: FunctionComponent<BackButtonViewProps> = (props: BackButtonViewProps) => {
  return (
    <Button
      className='back-button'
      type='text'
      icon={<ArrowLeftOutlined />}
      onClick={props.onBack}
      size='small'
    >
      Wstecz
    </Button>
  )
}

export default BackButtonView
