import { Row, Typography } from 'antd'
import React, { FunctionComponent } from 'react'
import BackButton from '../../components/backButton'

import './subpageLayout.less'

interface SubpageLayoutViewProps {
  children?: React.ReactNode
  title: string
  page: string
}

const SubpageLayoutView: FunctionComponent<SubpageLayoutViewProps> = (props) => {
  return (
    <div className='subpage-layout'>
      <div className='subpage-layout__header'>
        <Row className='subpage-layout__back-button-row'>
          <BackButton />
        </Row>
        <Row className='subpage-layout__navigation-row'>
          <Typography.Text>{props.page}</Typography.Text>
        </Row>
        <Row>
          <Typography.Text className='subpage-layout__title'>{props.title}</Typography.Text>
        </Row>
      </div>
      {props.children}
    </div>
  )
}

export default SubpageLayoutView
