import React from 'react'

import WorkerMapView from './WorkerMapView'
import useWorkerMap from './useWorkerMap'

const WorkerMap = (): JSX.Element => {
  const state = useWorkerMap()

  return <WorkerMapView {...state} />
}

export default WorkerMap
