import React, { Dispatch } from 'react'
import { AppActions, AppState } from './mainReducer'

type AppContextProps = {
  state: AppState
  dispatch: Dispatch<AppActions>
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const AppContext = React.createContext<AppContextProps>({})

export default AppContext
