import { Menu } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { jobShowRoutes } from '../../containers/Router/routes'
import ContentLoader from '../../components/ContentLoader'
import SubpageLayout from '../SubpageLayout'

import './jobShowLayout.less'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { Job } from '../../providers/ClientProvider/client/services/jobs/types'

interface JobShowLayoutViewProps {
  children?: React.ReactNode
  job?: Job
  menuSelectedKey?: string
  id: string
}

const JobShowLayoutView: FunctionComponent<JobShowLayoutViewProps> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const layouts = dictionary.layouts

  return (
    <ContentLoader loading={!props.job}>
      <SubpageLayout title={props.job ? `${props.job?.name}` : ''} page={t(layouts.jobs)}>
        <Menu
          mode='horizontal'
          selectedKeys={props.menuSelectedKey ? [props.menuSelectedKey] : undefined}
        >
          <Menu.Item key={jobShowRoutes.details.path}>
            <Link to={generatePath(jobShowRoutes.details.path, { id: props.id })} replace>
              {t(layouts.details)}
            </Link>
          </Menu.Item>
          <Menu.Item key={jobShowRoutes.workers.path}>
            <Link to={generatePath(jobShowRoutes.workers.path, { id: props.id })} replace>
              {t(layouts.workers)}
            </Link>
          </Menu.Item>
          <Menu.Item key={jobShowRoutes.locations.path}>
            <Link to={generatePath(jobShowRoutes.locations.path, { id: props.id })} replace>
              {t(layouts.locations)}
            </Link>
          </Menu.Item>
          <Menu.Item key={jobShowRoutes.map.path}>
            <Link to={generatePath(jobShowRoutes.map.path, { id: props.id })} replace>
              {t(layouts.map)}
            </Link>
          </Menu.Item>
        </Menu>
        <div className='job-show-layout'>{props.children}</div>
      </SubpageLayout>
    </ContentLoader>
  )
}

export default JobShowLayoutView
