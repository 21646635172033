import React from 'react'

import JobWorkersView from './JobWorkersView'
import useJobWorkers from './useJobWorkers'

const JobWorkers = (): JSX.Element => {
  const state = useJobWorkers()

  return <JobWorkersView {...state} />
}

export default JobWorkers
