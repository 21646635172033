import React, { FunctionComponent } from 'react'

import Router from '../Router'

import './App.less'

const App: FunctionComponent = () => {
  return (
    <>
      <Router />
    </>
  )
}

export default App
