import React from 'react'

import DeviceEditView from './DeviceEditView'
import useDeviceEdit from './useDeviceEdit'

const DeviceEdit = (): JSX.Element => {
  const state = useDeviceEdit()

  return <DeviceEditView {...state} />
}

export default DeviceEdit
