import React from 'react'

import TasksView from './TasksView'
import useTasks from './useTasks'

const Tasks = (): JSX.Element => {
  const state = useTasks()

  return <TasksView {...state} />
}

export default Tasks
