import React from 'react'

import BackButtonView from './BackButtonView'
import useBackButton from './useBackButton'

const BackButton = () => {
  const state = useBackButton()

  return <BackButtonView {...state} />
}

export default BackButton
