import React, { FunctionComponent } from 'react'
import { Scope, TranslateOptions } from 'i18n-js'
import i18n, { dictionary, Translation } from './translations'

type LocalizationContextType = {
  t: (scope: Scope, options?: TranslateOptions) => string
  locale: string
  setLocale: (v: string) => void
  dictionary: Translation
}

const DEFAULT_LOCAL = 'pl'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const LocalizationContext = React.createContext<LocalizationContextType>(null)

const LocalizationProvider: FunctionComponent = (props) => {
  const [locale, setLocale] = React.useState(DEFAULT_LOCAL)
  const localizationContext = React.useMemo(
    () => ({
      t: (scope: Scope, options?: TranslateOptions): string =>
        i18n.t(scope, { locale, ...options }),
      locale,
      setLocale,
      dictionary
    }),
    [locale]
  )

  return (
    <LocalizationContext.Provider value={localizationContext}>
      {props.children}
    </LocalizationContext.Provider>
  )
}

export default LocalizationProvider
