import { Form, message } from 'antd'
import { useCallback, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useDeleteMutation, useGetQuery, usePatchMutation } from '../../../../query'

const useGroupShow = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const groupsPage = dictionary.groupsPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const { data: group, status } = useGetQuery('groups', id)

  const [patchGroup, { status: patchStatus }] = usePatchMutation('groups')

  const [deleteGroup, { status: deleteStatus }] = useDeleteMutation('groups')

  const onFinish = useCallback(
    async (values) => {
      if (!id) {
        throw new Error(t(groupsPage.incorrectId))
      }

      try {
        await patchGroup({
          id,
          data: values
        })

        message.success(t(groupsPage.updated))
      } catch (e) {
        console.log(e)
      }
    },
    [id]
  )

  const onDelete = useCallback(async () => {
    if (!id) {
      throw new Error(t(groupsPage.incorrectId))
    }

    try {
      await deleteGroup(id)

      history.push(appRoutes.users.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  return {
    group,
    form,
    onFinish,
    patchLoading: patchStatus === 'loading',
    onDelete,
    deleteLoading: deleteStatus === 'loading'
  }
}
export default useGroupShow
