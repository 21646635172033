import { useGetQuery } from '../../query'

interface WorkerDeviceUseProps {
  deviceId?: string
}

const useWorkerDevice = (props: WorkerDeviceUseProps) => {
  const { data: device } = useGetQuery('devices', props.deviceId)

  return {
    device: device
  }
}
export default useWorkerDevice
