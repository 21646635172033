import React, { FunctionComponent } from 'react'

import LocationShowLayoutView from './WorkerShowLayoutView'
import useLocationShowLayout from './useLocationShowLayout'

interface LocationShowLayoutProps {
  children?: React.ReactNode
}

const LocationShowLayout: FunctionComponent<LocationShowLayoutProps> = (
  props: LocationShowLayoutProps
) => {
  const listLayoutState = useLocationShowLayout()

  return <LocationShowLayoutView {...props} {...listLayoutState} />
}

export default LocationShowLayout
