import React from 'react'

import TaskLocationsView from './TaskLocationsView'
import useTaskLocations from './useTaskLocations'

const TaskLocations = (): JSX.Element => {
  const state = useTaskLocations()

  return <TaskLocationsView {...state} />
}

export default TaskLocations
