import { useGetQuery } from '../../query'

interface WorkerDeviceUseProps {
  workerId?: string
}

const useDeviceWorker = (props: WorkerDeviceUseProps) => {
  const { data: worker } = useGetQuery('workers', props.workerId)

  return {
    worker: worker
  }
}
export default useDeviceWorker
