import React from 'react'

import LoginView from './LoginView'
import useLogin from './useLogin'

const Login = () => {
  const state = useLogin()

  return <LoginView {...state} />
}

export default Login
