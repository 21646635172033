import React from 'react'

import JobMapView from './JobMapView'
import useJobMap from './useJobMap'

const JobMap = (): JSX.Element => {
  const state = useJobMap()

  return <JobMapView {...state} />
}

export default JobMap
