import React, { FunctionComponent } from 'react'

import WorkerShowLayoutView from './WorkerShowLayoutView'
import useWorkerShowLayout from './useWorkerShowLayout'

interface WorkerShowLayoutProps {
  children?: React.ReactNode
}

const WorkerShowLayout: FunctionComponent<WorkerShowLayoutProps> = (
  props: WorkerShowLayoutProps
) => {
  const listLayoutState = useWorkerShowLayout()

  return <WorkerShowLayoutView {...props} {...listLayoutState} />
}

export default WorkerShowLayout
