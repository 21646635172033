import React from 'react'

import GroupEditView from './GroupDetailsView'
import useGroupShow from './useGroupDetails'

const GroupDetails = (): JSX.Element => {
  const state = useGroupShow()

  return <GroupEditView {...state} />
}

export default GroupDetails
