import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

const useBackButton = () => {
  const history = useHistory()

  const onBack = useCallback(() => {
    history.goBack()
  }, [])
  return {
    onBack
  }
}
export default useBackButton
