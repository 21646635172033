import React from 'react'

import WorkerCreateView from './WorkerCreateView'
import useWorkerCreate from './useWorkerCreate'

const WorkerCreate = (): JSX.Element => {
  const state = useWorkerCreate()

  return <WorkerCreateView {...state} />
}

export default WorkerCreate
