import React from 'react'

import LogOutView from './LogOutView'
import useLogOut from './useLogOut'

const LogOut = () => {
  useLogOut()

  return <LogOutView />
}

export default LogOut
