import { useEffect, useState } from 'react'
import { matchPath, useHistory, useParams } from 'react-router-dom'
import { LocationListener } from 'history'
import { appRoutes } from '../../containers/Router/routes'
import { useGetQuery } from '../../query'

const useLocationShowLayout = () => {
  const { id } = useParams<{ id: string }>()

  const history = useHistory()

  const [menuSelectedKey, setMenuSelectedKey] = useState()

  const { data: location } = useGetQuery('locations', id)

  useEffect(() => {
    const handleSelectedKey: LocationListener = (location) => {
      const menuSelectedKey = Object.entries(appRoutes.locationShow.routes)
        .map(([, value]) => value)
        .find((route) =>
          matchPath(location.pathname, {
            path: route.path,
            exact: true,
            strict: false
          })
        )?.path

      setMenuSelectedKey(menuSelectedKey)
    }

    // run the check on app start
    handleSelectedKey(history.location, 'PUSH')

    return history.listen(handleSelectedKey)
  }, [])

  return {
    menuSelectedKey,
    location,
    id
  }
}

export default useLocationShowLayout
