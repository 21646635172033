import React from 'react'

import GroupWorkersView from './GroupWorkersView'
import useGroupWorkers from './useGroupWorkers'

const GroupWorkers = (): JSX.Element => {
  const state = useGroupWorkers()

  return <GroupWorkersView {...state} />
}

export default GroupWorkers
