/* eslint-disable react/display-name */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Row, Select, Spin, Table } from 'antd'
import { ColumnType, SortOrder } from 'antd/lib/table/interface'
import React, { FunctionComponent, HTMLAttributes, useContext } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import { onTableChange } from '../../../../components/useTable'
import WorkerDevice from '../../../../components/workerDevice'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'

import './groupWorkers.less'

interface ColumnProps {
  dictionary: any
  t: any
  onRemoveWorker: (workerId: string) => void
  removeWorkerLoading: boolean
  currentSort: { [key: string]: SortOrder }
}

export const Columns = (props: ColumnProps): ColumnType<Worker>[] => {
  return [
    {
      title: props.t(props.dictionary.workersPage.firstName),
      dataIndex: 'firstName',
      sorter: true,
      sortOrder: props?.currentSort['firstName']
    },
    {
      title: props.t(props.dictionary.workersPage.lastName),
      dataIndex: 'lastName',
      sorter: true,
      sortOrder: props.currentSort['lastName']
    },
    {
      title: props.t(props.dictionary.general.device),
      dataIndex: 'deviceId',
      render: (value: string, record: Worker): JSX.Element | undefined => (
        <WorkerDevice deviceId={record.deviceId} />
      ),
      responsive: ['md']
    },
    {
      title: props.t(props.dictionary.workersPage.score),
      dataIndex: 'score',
      sorter: true,
      sortOrder: props?.currentSort['score'],
      responsive: ['md']
    },
    {
      width: 3,
      // eslint-disable-next-line react/display-name
      render: (value: string, record): JSX.Element => (
        <Button
          icon={<DeleteOutlined />}
          danger
          onClick={async (event) => {
            await event.stopPropagation()
            props.onRemoveWorker(record._id)
          }}
          loading={props.removeWorkerLoading}
        />
      )
    }
  ]
}

interface GroupWorkersViewProps {
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Worker>
  workers?: Worker[]
  workersLoading: boolean
  availableWorkers?: Worker[]
  availableWorkersLoading: boolean
  setWorkerSearch: (value: string) => void
  setSelectedWorker: (value: string) => void
  handleOnRowClick: (id: string) => void
  onRemoveWorker: (workerId: string) => void
  removeWorkerLoading: boolean
  onAddWorker: () => void
  addWorkerLoading: boolean
}

const GroupWorkersView: FunctionComponent<GroupWorkersViewProps> = (
  props: GroupWorkersViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)

  return (
    <div className='group-workers'>
      <ContentLoader loading={props.workersLoading}>
        <div className='group-workers__container'>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Select
                className='group-workers__select'
                showSearch
                showArrow
                placeholder={t(dictionary.general.chooseFromList)}
                filterOption={false}
                onSelect={(value): void => {
                  props.setSelectedWorker(value.toString())
                }}
                onSearch={props.setWorkerSearch}
              >
                {props.availableWorkersLoading ? (
                  <Select.Option value=''>
                    <Spin size='small' />
                  </Select.Option>
                ) : (
                  props.availableWorkers?.map((worker) => {
                    return (
                      <Select.Option key={worker._id} value={worker._id}>
                        {`${worker.firstName} ${worker.lastName}`}
                      </Select.Option>
                    )
                  })
                )}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Button onClick={props.onAddWorker} loading={props.addWorkerLoading}>
                {t(dictionary.general.add)}
              </Button>
            </Col>
          </Row>
          <Table
            rowClassName='group-workers__location-row'
            columns={Columns({
              t,
              dictionary,
              onRemoveWorker: props.onRemoveWorker,
              removeWorkerLoading: props.removeWorkerLoading,
              currentSort: props.currentSort
            })}
            loading={props.workersLoading}
            dataSource={props.workers}
            rowKey='_id'
            onChange={props.onTableChange}
            pagination={{
              total: props.total,
              current: props.currentPage,
              showTotal: (total, range): string =>
                `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
                  dictionary.general.items
                )}`,
              showSizeChanger: false
            }}
            onRow={(record): HTMLAttributes<HTMLElement> => {
              return {
                onClick: (): void => props.handleOnRowClick(record._id)
              }
            }}
          />
        </div>
      </ContentLoader>
    </div>
  )
}

export default GroupWorkersView
