import React, { FunctionComponent } from 'react'
import ClientContext, { IClient } from './client'

interface ClientProviderViewProps {
  client: IClient
}

const ClientProviderView: FunctionComponent<ClientProviderViewProps> = (props) => {
  return <ClientContext.Provider value={props.client}>{props.children}</ClientContext.Provider>
}

export default ClientProviderView
