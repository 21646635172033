import React, { FunctionComponent } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import Map from '../../../../components/map'
import { Task } from '../../../../providers/ClientProvider/client/services/tasks/types'

import './taskMap.less'

interface TaskMapViewProps {
  task?: Task
}

const TaskMapView: FunctionComponent<TaskMapViewProps> = (props: TaskMapViewProps) => {
  return (
    <div className='job-map'>
      <ContentLoader loading={!props.task}>
        <Map geoData={props.task?.mapData} />
      </ContentLoader>
    </div>
  )
}

export default TaskMapView
