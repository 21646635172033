import React from 'react'

import DevicesView from './DevicesView'
import useDevices from './useDevices'

const Devices = (): JSX.Element => {
  const state = useDevices()

  return <DevicesView {...state} />
}

export default Devices
