import React, { FunctionComponent, useContext } from 'react'

import logo from '../../images/logo365x139.png'

import './login.less'

import { Button, Card, Form, Input } from 'antd'
import { LocalizationContext } from '../../providers/LocalizationProvider'

type Props = {
  saving: boolean
  onFinish: (values: any) => void
}

const LoginView: FunctionComponent<Props> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const loginPage = dictionary.loginPage

  return (
    <div className='login'>
      <Card className='login__card'>
        <img src={logo} alt='logo' className='login__logo' />
        <Form name='login' layout='vertical' onFinish={props.onFinish} size='large'>
          <Form.Item
            name='email'
            label='Email'
            rules={[{ required: true, message: t(dictionary.general.formError), type: 'email' }]}
          >
            <Input size='large' />
          </Form.Item>
          <Form.Item
            name='password'
            label={t(loginPage.passwors)}
            rules={[{ required: true, message: t(dictionary.general.formError) }]}
          >
            <Input type='password' />
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login__form-button'
            disabled={props.saving}
          >
            {props.saving ? t(loginPage.loading) : t(loginPage.login)}
          </Button>
        </Form>
      </Card>
    </div>
  )
}

export default LoginView
