import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form'
import './workerCreate.less'
import SubpageLayout from '../../../layouts/SubpageLayout'
import { LocalizationContext } from '../../../providers/LocalizationProvider'

interface WorkerCreateViewProps {
  form: FormInstance
  onFinish: (v: any) => void
  createLoading: boolean
}

const WorkerCreateView: FunctionComponent<WorkerCreateViewProps> = (
  props: WorkerCreateViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const workersPage = dictionary.workersPage

  return (
    <div className='create-worker'>
      <SubpageLayout title={t(workersPage.addWorker)} page={t(dictionary.layouts.workers)}>
        <Form
          form={props.form}
          name='create-worker'
          onFinish={props.onFinish}
          layout='vertical'
          className='app-layout__form'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item
                name='firstName'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(workersPage.firstName)}
              >
                <Input placeholder={t(workersPage.firstName)} type='text' />
              </Form.Item>
              <Form.Item
                name='lastName'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(workersPage.lastName)}
              >
                <Input placeholder={t(workersPage.lastName)} type='text' />
              </Form.Item>
              <Form.Item name='phoneNumber' label={t(workersPage.phoneNumber)}>
                <Input placeholder={t(workersPage.phoneNumber)} type='text' />
              </Form.Item>
              <Form.Item
                name='email'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={'Email'}
              >
                <Input placeholder={'Email'} type='text' />
              </Form.Item>
              <Form.Item
                name='identifier'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(workersPage.identifier)}
              >
                <Input placeholder={t(workersPage.identifier)} type='text' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item
                name='startDate'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(workersPage.startDate)}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name='endDate' label={t(workersPage.endDate)}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name='active' label={t(workersPage.active)} valuePropName='checked'>
                <Switch />
              </Form.Item>
              <Form.Item name='manager' label={t(workersPage.manager)} valuePropName='checked'>
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              className='app-layout__form-button'
              loading={props.createLoading}
            >
              {t(workersPage.createWorker)}
            </Button>
          </Row>
        </Form>
      </SubpageLayout>
    </div>
  )
}

export default WorkerCreateView
