import i18n from 'i18n-js'
import en from './en'
import pl from './pl'

export type Dictionary = {
  [key: string]: {
    [key: string]: string
  }
}

export type Translation = typeof pl

const tmpDictionary: Dictionary = {}

Object.entries(pl).map(([key, value]) => {
  tmpDictionary[key] = {}
  Object.keys((pl as Dictionary)[key]).map((key2) => {
    tmpDictionary[key][key2] = `${key}.${key2}`
  })
})

const dictionary: Translation = tmpDictionary as Translation

export { dictionary }

i18n.fallbacks = true
i18n.translations = { en, pl }

export default i18n
