/* eslint-disable react/display-name */
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { ColumnType } from 'antd/lib/table/interface'
import React, { FunctionComponent, useContext } from 'react'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { onTableChange } from '../useTable'
import WorkerDevice from '../workerDevice'

interface ColumnProps {
  dictionary: any
  t: any
  onDeleteWorker: (workerId: string) => void
  deleteJobWorkerLoading: boolean
}

export const Columns = (props: ColumnProps): ColumnType<Worker>[] => {
  return [
    {
      title: props.t(props.dictionary.workersPage.firstName),
      responsive: ['sm'],
      dataIndex: 'firstName'
    },
    {
      title: props.t(props.dictionary.workersPage.lastName),
      dataIndex: 'lastName'
    },
    {
      title: props.t(props.dictionary.general.device),
      responsive: ['md'],
      dataIndex: 'deviceId',
      render: (value: string, record: Worker): JSX.Element | undefined => (
        <WorkerDevice deviceId={record.deviceId} />
      )
    },
    {
      width: 3,
      render: (value: string, record: Worker): JSX.Element => (
        <Button
          icon={<DeleteOutlined />}
          loading={props.deleteJobWorkerLoading}
          danger
          onClick={(event): void => {
            event.stopPropagation()
            props.onDeleteWorker(record._id)
          }}
        />
      )
    }
  ]
}

interface WorkersInJobViewProps {
  workers?: Worker[]
  workersLoading: boolean
  currentPage: number
  onTableChange: onTableChange<Worker>
  total: number
  onDeleteWorker: (workerId: string) => void
  deleteJobWorkerLoading: boolean
}

const WorkersInJobView: FunctionComponent<WorkersInJobViewProps> = (
  props: WorkersInJobViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)

  return (
    <Table
      columns={Columns({
        t,
        dictionary,
        onDeleteWorker: props.onDeleteWorker,
        deleteJobWorkerLoading: props.deleteJobWorkerLoading
      })}
      loading={props.workersLoading}
      dataSource={props.workers}
      rowKey='_id'
      onChange={props.onTableChange}
      pagination={{
        total: props.total,
        current: props.currentPage,
        showTotal: (total, range): string =>
          `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
            dictionary.general.items
          )}`,
        showSizeChanger: false
      }}
    />
  )
}

export default WorkersInJobView
