import React from 'react'

import UsersView from './UsersView'
import useUsers from './useUsers'

const Users = (): JSX.Element => {
  const state = useUsers()

  return <UsersView {...state} />
}

export default Users
