import React from 'react'

import WorkersView from './WorkersView'
import useWorkers from './useWorkers'

const Workers = (): JSX.Element => {
  const state = useWorkers()

  return <WorkersView {...state} />
}

export default Workers
