import React, { useCallback, useMemo } from 'react'
import { Tag, Row, Typography } from 'antd'
import qs from 'qs'
import { useHistory } from 'react-router-dom'
import startCase from 'lodash/startCase'
import { useFilterTags } from './useFilterTags'

const FilterTags = () => {
  const { query } = useFilterTags()
  const history = useHistory()

  const queries = useMemo(() => {
    const filtered = Object.entries(query).filter((entry) => !entry[0].includes('$'))
    return filtered.map((entry) => {
      if (entry[1] === 'true') {
        return {
          key: entry[0],
          value: 'Yes'
        }
      }
      if (entry[1] === 'false') {
        return {
          key: entry[0],
          value: 'No'
        }
      }
      return {
        key: entry[0],
        value: entry[1]
      }
    })
  }, [query])

  const onClose = useCallback(
    (val: any) => {
      const newQuery = query
      delete newQuery[val]
      history.replace(`${history.location.pathname}?${qs.stringify(newQuery)}`)
    },
    [history, query]
  )

  const tags = useMemo(
    () =>
      queries.map((tag: { key: string; value: string & { $regex: string } }, index: number) => {
        if (!tag) return
        if (tag.value['$regex']) {
          return (
            <Tag
              closable
              onClose={() => onClose(tag.key)}
              key={`${tag.key}${tag.value['$regex']}${index}`}
            >
              <Typography.Text strong>{startCase(tag.key)}</Typography.Text>:{' '}
              {startCase(tag.value['$regex'])}
            </Tag>
          )
        }

        return (
          <Tag closable onClose={() => onClose(tag.key)} key={`${tag.key}${index}${tag.value}`}>
            <Typography.Text strong>{startCase(tag.key)}</Typography.Text>:{' '}
            {`${startCase(tag.value)}`}
          </Tag>
        )
      }),
    [onClose, queries]
  )

  return <Row style={{ margin: '10px 0' }}>{tags}</Row>
}

export default FilterTags
