import React from 'react'

import DeviceWorkerView from './DeviceWorkerView'
import useDeviceWorker from './useDeviceWorker'

interface DeviceWorkerProps {
  workerId?: string
}

const DeviceWorker = (props: DeviceWorkerProps) => {
  const state = useDeviceWorker({ workerId: props.workerId })

  return <DeviceWorkerView {...state} {...props} />
}

export default DeviceWorker
