import { useParams } from 'react-router-dom'
import { useGetQuery } from '../../../../query'

const useJobMap = () => {
  const { id } = useParams<{ id: string }>()

  const { data: job } = useGetQuery('jobs', id)

  return {
    job
  }
}
export default useJobMap
