import React, { Dispatch } from 'react'
import { MainActions, MainState } from './mainReducer'

type MainContextProps = {
  state: MainState
  dispatch: Dispatch<MainActions>
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const MainContext = React.createContext<MainContextProps>({})

export default MainContext
