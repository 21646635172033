import React from 'react'

import WorkersInJobView from './WorkersInJobView'
import useWorkersInJob from './useWorkersInJob'
import { WorkersToAssign } from '../../providers/ClientProvider/client/services/tasks/types'

interface WorkersInJobProps {
  workersToAssign: WorkersToAssign
  jobId?: string
}

const WorkersInJob = (props: WorkersInJobProps): JSX.Element => {
  const state = useWorkersInJob({ ...props })

  return <WorkersInJobView {...state} />
}

export default WorkersInJob
