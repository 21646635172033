import React from 'react'

import TasksCreateView from './TasksCreateView'
import useTasksCreate from './useTasksCreate'

const TasksCreate = (): JSX.Element => {
  const state = useTasksCreate()

  return <TasksCreateView {...state} />
}

export default TasksCreate
