import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Popconfirm, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'

import './groupDetails.less'

import ContentLoader from '../../../../components/ContentLoader'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { Group } from '../../../../providers/ClientProvider/client/services/groups/types'

interface GroupEditViewProps {
  group?: Group
  form: FormInstance
  onFinish: (v: any) => void
  patchLoading: boolean
  onDelete: () => void
  deleteLoading: boolean
}

const GroupEditView: FunctionComponent<GroupEditViewProps> = (props: GroupEditViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const groupsPage = dictionary.groupsPage

  return (
    <div className='edit-group'>
      <ContentLoader loading={!props.group}>
        <Form
          form={props.form}
          name='edit-group'
          onFinish={props.onFinish}
          initialValues={props.group}
          className='app-layout__form'
          layout='vertical'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item
                name='name'
                label={t(groupsPage.name)}
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
              >
                <Input placeholder={t(groupsPage.name)} type='text' />
              </Form.Item>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              loading={props.patchLoading}
              className='app-layout__form-button'
            >
              {t(dictionary.general.save)}
            </Button>
            <Popconfirm
              placement='left'
              title={t(groupsPage.deleteConfirmation)}
              okText={t(dictionary.general.yes)}
              cancelText={t(dictionary.general.cancel)}
              onConfirm={props.onDelete}
            >
              <Button danger loading={props.deleteLoading} className='app-layout__form-button'>
                {t(dictionary.general.delete)}
              </Button>
            </Popconfirm>
          </Row>
        </Form>
      </ContentLoader>
    </div>
  )
}

export default GroupEditView
