import React from 'react'

import GroupCreateView from './GroupCreateView'
import useGroupCreate from './useGroupCreate'

const GroupCreate = (): JSX.Element => {
  const state = useGroupCreate()

  return <GroupCreateView {...state} />
}

export default GroupCreate
