import React, { FunctionComponent } from 'react'
import useClientProvider from './useClientProvider'
import ClientProviderView from './ClientProviderView'

const ClientProvider: FunctionComponent = (props) => {
  const state = useClientProvider()

  return <ClientProviderView {...state}>{props.children}</ClientProviderView>
}

export default ClientProvider
