import React from 'react'

import WorkerDeviceView from './WorkerDeviceView'
import useWorkerDevice from './useWorkerDevice'

interface WorkerDeviceProps {
  deviceId?: string
}

const WorkerDevice = (props: WorkerDeviceProps) => {
  const state = useWorkerDevice({ deviceId: props.deviceId })

  return <WorkerDeviceView {...state} {...props} />
}

export default WorkerDevice
