import React from 'react'
import { Calendar } from '../../providers/ClientProvider/client/services/tasks/types'

import CalendarInputView from './CalendarInputView'
import useCalendarInput from './useCalendarInput'

interface CalendarInputProps {
  value?: Calendar
}

const CalendarInput = (props: CalendarInputProps) => {
  const state = useCalendarInput(props)

  return <CalendarInputView {...state} {...props} />
}

export default CalendarInput
