import React, { FunctionComponent, useMemo, useReducer } from 'react'

import AppLayoutView from './AppLayoutView'
import AppContext from './context/AppContext'
import { appInitialState, appReducer } from './context/mainReducer'
import useAppLayout from './useAppLayout'
import useReAuthenticate from './useReAuthenticate'

const AppLayout: FunctionComponent = (props) => {
  const [state, dispatch] = useReducer(appReducer, appInitialState)
  const appLayoutState = useAppLayout({ state, dispatch })
  useReAuthenticate({ dispatch, state })

  const value = useMemo(() => {
    return {
      state,
      dispatch
    }
  }, [state, dispatch])

  return (
    <AppContext.Provider value={value}>
      <AppLayoutView {...props} {...state} {...appLayoutState} />
    </AppContext.Provider>
  )
}

export default AppLayout
