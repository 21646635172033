import React, { FunctionComponent } from 'react'

import SubpageLayoutView from './EditLayoutView'
import useSubpageLayout from './useSubpageLayout'

interface SubpageLayoutProps {
  children?: React.ReactNode
  title: string
  page: string
}

const SubpageLayout: FunctionComponent<SubpageLayoutProps> = (props: SubpageLayoutProps) => {
  const listLayoutState = useSubpageLayout()

  return <SubpageLayoutView {...props} {...listLayoutState} />
}

export default SubpageLayout
