import React from 'react'

import DeviceCreateView from './DeviceCreateView'
import useDeviceCreate from './useDeviceCreate'

const DeviceCreate = (): JSX.Element => {
  const state = useDeviceCreate()

  return <DeviceCreateView {...state} />
}

export default DeviceCreate
