import message from 'antd/lib/message'
import { useCallback, useContext } from 'react'
import { queryCache } from 'react-query'
import { WorkersToAssign } from '../../providers/ClientProvider/client/services/tasks/types'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { useDeleteMutation, useFindQuery } from '../../query'
import useTable from '../useTable'

interface UseWorkersInJobProps {
  workersToAssign: WorkersToAssign
  jobId?: string
}

const useWorkersInJob = (props: UseWorkersInJobProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  const { query, onTableChange, currentSort, currentPage } = useTable<Worker>()

  const { data: jobWorkersRes, status: jobWorkersResStatus } = useFindQuery(
    'jobWorkers',
    props.jobId
      ? {
          ...query,
          groupId: props.workersToAssign.groupId,
          jobId: props.jobId
        }
      : false
  )

  const { data: workersRes, status: workersStatus } = useFindQuery(
    'workers',
    jobWorkersRes && jobWorkersRes.total
      ? {
          _id: { $in: jobWorkersRes.data.map((jobWorker) => jobWorker.workerId) }
        }
      : false
  )

  const [deleteJobWorker, { status: deleteJobWorkerStatus }] = useDeleteMutation('jobWorkers')

  const onDeleteWorker = useCallback(
    async (workerId: string) => {
      try {
        if (!jobWorkersRes) return

        const jobWorkerToDelete = jobWorkersRes.data.find(
          (jobWorker) => jobWorker.workerId === workerId
        )
        if (!jobWorkerToDelete) return

        await deleteJobWorker(jobWorkerToDelete._id)

        message.success(t(jobsPage.workerRemoved))

        await queryCache.refetchQueries('jobWorkers')
      } catch (e) {
        console.log(e)
      }
    },
    [jobWorkersRes]
  )
  return {
    workers: workersRes?.data,
    workersLoading: workersStatus === 'loading' || jobWorkersResStatus === 'loading',
    onTableChange,
    total: jobWorkersRes?.total || 0,
    currentPage,
    onDeleteWorker,
    deleteJobWorkerLoading: deleteJobWorkerStatus === 'loading'
  }
}
export default useWorkersInJob
