import { Form } from 'antd'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes } from '../../../containers/Router/routes'
import { useCreateMutation } from '../../../query'

const useGroupCreate = () => {
  const [form] = Form.useForm()

  const history = useHistory()

  const [createGroup, { status: createStatus }] = useCreateMutation('groups')

  const onFinish = useCallback(async () => {
    try {
      const values = await form.validateFields()
      const group = await createGroup({
        ...values
      })

      history.replace(
        generatePath(appRoutes.groupShow.path, {
          id: group._id
        })
      )
    } catch (e) {
      console.log(e)
    }
  }, [form])

  return {
    form,
    onFinish,
    createLoading: createStatus === 'loading'
  }
}
export default useGroupCreate
