import { Form } from 'antd'
import { useCallback, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { jobShowRoutes } from '../../../containers/Router/routes'
import { useCreateMutation, useFindQuery } from '../../../query'

const useJobCreate = () => {
  const [form] = Form.useForm()

  const history = useHistory()

  const [createJob, { status: createStatus }] = useCreateMutation('jobs')

  const [taskSearch, setTaskSearch] = useState<string>('')

  const { data: tasksRes } = useFindQuery('tasks', {
    name: { $regex: taskSearch, $options: 'i' }
  })

  const onFinish = useCallback(async () => {
    try {
      const values = await form.validateFields()

      const job = await createJob({
        ...values
      })

      history.replace(
        generatePath(jobShowRoutes.details.path, {
          id: job._id
        })
      )
    } catch (e) {
      console.log(e)
    }
  }, [form])

  return {
    form,
    onFinish,
    tasks: tasksRes?.data,
    createLoading: createStatus === 'loading',
    setTaskSearch
  }
}
export default useJobCreate
