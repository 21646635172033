import React from 'react'

import LocationsView from './LocationsView'
import useLocations from './useLocations'

const Workers = (): JSX.Element => {
  const state = useLocations()

  return <LocationsView {...state} />
}

export default Workers
