import React, { FunctionComponent } from 'react'

import './workerDetails.less'

interface WorkerDetailsViewProps {}

const WorkerDetailsView: FunctionComponent<WorkerDetailsViewProps> = () => {
  return <div className='edit-worker'>Details</div>
}

export default WorkerDetailsView
