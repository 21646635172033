import React from 'react'

import WorkerDetailsView from './WorkerDetailsView'
import useWorkerDetails from './useWorkerDetails'

const WorkerDetails = (): JSX.Element => {
  const state = useWorkerDetails()

  return <WorkerDetailsView {...state} />
}

export default WorkerDetails
