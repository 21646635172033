import { useState } from 'react'

import { initClient } from './client'
import { useHistory } from 'react-router-dom'

const useClientProvider = () => {
  const history = useHistory()

  const [client] = useState(initClient(history))

  return {
    client
  }
}

export default useClientProvider
