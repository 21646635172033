import { useCallback, useEffect, useMemo, useState } from 'react'
import { Key, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/lib/table/interface'
import qs from 'qs'
import { useHistory } from 'react-router-dom'
import { LocationListener } from 'history'
import { TablePaginationConfig } from 'antd/es/table'

interface Query {
  $sort?: {
    [x: string]: '1' | '-1'
  }
  $skip?: number
  $limit?: number
  [key: string]: any
}

const DEFAULT_SKIP = '0'
const DEFAULT_LIMIT = '10'

interface TableOptions {
  useQueryParams: boolean
}

export type onTableChange<T> = (
  pagination: TablePaginationConfig,
  filters: Record<string, Key[] | null>,
  sorter: SorterResult<T> | SorterResult<T>[],
  extra: TableCurrentDataSource<T>
) => void

function useTable<Model>(options: TableOptions = { useQueryParams: true }) {
  const history = useHistory()

  const [query, setQuery] = useState<Query>({
    $skip: Number(DEFAULT_SKIP),
    $limit: Number(DEFAULT_LIMIT)
  })

  useEffect(() => {
    if (!options.useQueryParams) {
      return
    }

    const handleQuery: LocationListener = (location) => {
      const query = qs.parse(history.location.search.substr(1))

      if (!query['$skip']) {
        query['$skip'] = DEFAULT_SKIP
      }

      if (!query['$limit']) {
        query['$limit'] = DEFAULT_LIMIT
      }

      setQuery(query)
    }

    // run handleQuery check on app start
    handleQuery(history.location, 'PUSH')

    return history.listen(handleQuery)
  }, [])

  const onTableChange: onTableChange<Model> = useCallback(
    (pagination, filters, sorter, extra) => {
      const newQuery = { ...query }

      if (pagination.current && pagination.pageSize) {
        newQuery['$skip'] = (pagination.current - 1) * pagination.pageSize
      }

      if (!Array.isArray(sorter)) {
        if (sorter.field && typeof sorter.field === 'string' && sorter.order) {
          let order: '1' | '-1' | null = null
          if (sorter.order === 'ascend') order = '1'
          if (sorter.order === 'descend') order = '-1'

          if (order) {
            newQuery['$sort'] = {
              [sorter.field]: order
            }
          }
        } else {
          delete newQuery['$sort']
        }
      }

      if (options.useQueryParams) {
        history.replace(`${history.location.pathname}?${qs.stringify(newQuery)}`)
      } else {
        setQuery(newQuery)
      }
    },
    [query]
  )

  const currentSort = useMemo<{ [key: string]: SortOrder }>(() => {
    if (query['$sort']) {
      const field = Object.keys(query['$sort'])[0]
      let sortOrder: SortOrder = null

      switch (query['$sort'][field]) {
        case '1': {
          sortOrder = 'ascend'
          break
        }
        case '-1': {
          sortOrder = 'descend'
          break
        }
      }
      return {
        [field]: sortOrder
      }
    }

    return {}
  }, [query])

  const currentPage = useMemo(() => {
    if (query['$skip'] && query['$limit']) {
      return query['$skip'] / query['$limit'] + 1
    }

    return 1
  }, [query])

  return {
    query,
    onTableChange,
    currentSort,
    currentPage
  }
}

export default useTable
