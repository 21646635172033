import { Menu } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { workerShowRoutes } from '../../containers/Router/routes'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import ContentLoader from '../../components/ContentLoader'
import SubpageLayout from '../SubpageLayout'

import './workerShowLayout.less'
import { LocalizationContext } from '../../providers/LocalizationProvider'

interface WorkerShowLayoutViewProps {
  children?: React.ReactNode
  worker?: Worker
  menuSelectedKey?: string
  id: string
}

const WorkerShowLayoutView: FunctionComponent<WorkerShowLayoutViewProps> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const layouts = dictionary.layouts

  return (
    <ContentLoader loading={!props.worker}>
      <SubpageLayout
        title={props.worker ? `${props.worker?.firstName} ${props.worker?.lastName}` : ''}
        page={t(layouts.workers)}
      >
        <Menu
          mode='horizontal'
          selectedKeys={props.menuSelectedKey ? [props.menuSelectedKey] : undefined}
        >
          <Menu.Item key={workerShowRoutes.details.path}>
            <Link to={generatePath(workerShowRoutes.details.path, { id: props.id })} replace>
              {t(layouts.details)}
            </Link>
          </Menu.Item>
          <Menu.Item key={workerShowRoutes.data.path}>
            <Link to={generatePath(workerShowRoutes.data.path, { id: props.id })} replace>
              {t(layouts.data)}
            </Link>
          </Menu.Item>
          <Menu.Item key={workerShowRoutes.map.path}>
            <Link to={generatePath(workerShowRoutes.map.path, { id: props.id })} replace>
              {t(layouts.map)}
            </Link>
          </Menu.Item>
        </Menu>
        <div className='worker-show-layout'>{props.children}</div>
      </SubpageLayout>
    </ContentLoader>
  )
}

export default WorkerShowLayoutView
