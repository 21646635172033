import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import './tasksCreate.less'
import SubpageLayout from '../../../layouts/SubpageLayout'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { Worker } from '../../../providers/ClientProvider/client/services/workers/types'
import TaskGroupsFormElement from '../../../components/taskGroupsFormElement'
import { Group } from '../../../providers/ClientProvider/client/services/groups/types'

interface TasksCreateViewProps {
  form: FormInstance
  onFinish: (v: any) => void
  workers: Worker[]
  createLoading: boolean
  setGroupSearch: (value: string) => void
  setWorkerSearch: (value: string) => void
  groups?: Group[]
}

const TasksCreateView: FunctionComponent<TasksCreateViewProps> = (props: TasksCreateViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const tasksPage = dictionary.tasksPage

  return (
    <div className='create-task'>
      <SubpageLayout title={t(tasksPage.addTask)} page={t(dictionary.layouts.tasks)}>
        <Form
          form={props.form}
          name='create-task'
          onFinish={props.onFinish}
          layout='vertical'
          className='app-layout__form'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(tasksPage.name)}
              >
                <Input placeholder={t(tasksPage.name)} type='text' />
              </Form.Item>
              <Form.Item name='client' label={t(tasksPage.client)}>
                <Input placeholder={t(tasksPage.client)} type='text' />
              </Form.Item>
              <Form.Item name='description' label={t(tasksPage.description)}>
                <Input.TextArea placeholder={t(tasksPage.description)} rows={4} />
              </Form.Item>
              <Form.Item name='contractNumber' label={t(tasksPage.contractNumber)}>
                <Input placeholder={t(tasksPage.contractNumber)} type='text' />
              </Form.Item>
              <Form.Item name='coordinator' label={t(tasksPage.coordinator)}>
                <Select
                  allowClear
                  showSearch
                  showArrow
                  placeholder={t(dictionary.general.chooseFromList)}
                  filterOption
                  optionFilterProp={'filter'}
                >
                  {props.workers.map((worker) => (
                    <Select.Option
                      key={worker._id}
                      value={worker._id}
                      filter={`${worker.firstName} ${worker.lastName}`}
                    >
                      {worker.firstName} {worker.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='contactPerson' label={t(tasksPage.contactPerson)}>
                <Select
                  allowClear
                  showSearch
                  showArrow
                  placeholder={t(dictionary.general.chooseFromList)}
                  filterOption
                  optionFilterProp={'filter'}
                  onSearch={props.setWorkerSearch}
                >
                  {props.workers.map((worker) => (
                    <Select.Option
                      key={worker._id}
                      value={worker._id}
                      filter={`${worker.firstName} ${worker.lastName}`}
                    >
                      {worker.firstName} {worker.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <TaskGroupsFormElement setGroupSearch={props.setGroupSearch} groups={props.groups} />
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              className='app-layout__form-button'
              loading={props.createLoading}
            >
              {t(tasksPage.createTask)}
            </Button>
          </Row>
        </Form>
      </SubpageLayout>
    </div>
  )
}

export default TasksCreateView
