import React, { FunctionComponent } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import './jobMap.less'
import Map from '../../../../components/map'
import { Job } from '../../../../providers/ClientProvider/client/services/jobs/types'

interface JobMapViewProps {
  job?: Job
}

const JobMapView: FunctionComponent<JobMapViewProps> = (props: JobMapViewProps) => {
  return (
    <div className='job-map'>
      <ContentLoader loading={!props.job}>
        <Map geoData={props.job?.mapData} />
      </ContentLoader>
    </div>
  )
}

export default JobMapView
