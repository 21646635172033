import React, { FunctionComponent } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import Map from '../../../../components/map'

import './workerMap.less'

interface WorkerMapViewProps {
  worker?: Worker
}

const WorkerMapView: FunctionComponent<WorkerMapViewProps> = (props: WorkerMapViewProps) => {
  return (
    <div className='worker-map'>
      <ContentLoader loading={!props.worker}>
        <Map />
      </ContentLoader>
    </div>
  )
}

export default WorkerMapView
