import ClientContext, { ServiceModels } from './providers/ClientProvider/client'
import { useContext } from 'react'
import {
  MutateFunction,
  MutationResult,
  queryCache,
  QueryResult,
  useMutation,
  useQuery
} from 'react-query'
import { Paginated } from '@feathersjs/feathers'
import { message } from 'antd'

const onError = (e: any) => {
  console.debug(e)
  message.error(e?.message || 'Unknown request error')
}

export function useGetQuery<L extends keyof ServiceModels>(
  service: L,
  id?: string | null
): QueryResult<ServiceModels[L]> {
  const client = useContext(ClientContext)

  // IMPORTANT - need to have any here
  return useQuery<any, string>({
    queryKey: id,
    queryFn: (id) => client.service(service).get(id),
    config: {
      onError
    }
  })
}

export function useFindQuery<L extends keyof ServiceModels>(
  service: L,
  query: object | false
): QueryResult<Paginated<ServiceModels[L]>> {
  const client = useContext(ClientContext)

  // IMPORTANT - need to have any here
  return useQuery<any, any>({
    queryKey: query ? [service, query] : false,
    queryFn: () => client.service(service).find({ query }),
    config: {
      onError
    }
  })
}

export function useCreateMutation<L extends keyof ServiceModels>(
  service: L
): [MutateFunction<ServiceModels[L], Partial<ServiceModels[L]>>, MutationResult<ServiceModels[L]>] {
  const client = useContext(ClientContext)

  // IMPORTANT - need to have any here
  return useMutation<any, Partial<ServiceModels[L]>>(
    (data) =>
      // @ts-ignore
      client.service(service).create(data),
    {
      onSuccess: async (data) => {
        await queryCache.refetchQueries(service)
      },
      onError,
      throwOnError: true
    }
  )
}

export function useCreateMultiMutation<L extends keyof ServiceModels>(
  service: L
): [
  MutateFunction<ServiceModels[L][], Partial<ServiceModels[L]>[]>,
  MutationResult<ServiceModels[L][]>
] {
  const client = useContext(ClientContext)

  // IMPORTANT - need to have any here
  return useMutation<any, Partial<ServiceModels[L]>[]>(
    (data) =>
      // @ts-ignore
      client.service(service).create(data),
    {
      onSuccess: async (data) => {
        await queryCache.refetchQueries(service)
      },
      onError,
      throwOnError: true
    }
  )
}

interface PatchParams<T> {
  id: string
  data: Partial<T>
}

export function usePatchMutation<L extends keyof ServiceModels>(
  service: L
): [
  MutateFunction<ServiceModels[L], PatchParams<ServiceModels[L]>>,
  MutationResult<ServiceModels[L]>
] {
  const client = useContext(ClientContext)

  // IMPORTANT - need to have any here
  return useMutation<any, PatchParams<ServiceModels[L]>>(
    (params) => client.service(service).patch(params.id, params.data),
    {
      onSuccess: async (data) => {
        await queryCache.refetchQueries(service)
        await queryCache.setQueryData(data._id, (previous: any) => ({ ...previous, ...data }))
      },
      onError,
      throwOnError: true
    }
  )
}

export function useDeleteMutation<L extends keyof ServiceModels>(
  service: L
): [MutateFunction<ServiceModels[L], string>, MutationResult<ServiceModels[L]>] {
  const client = useContext(ClientContext)

  // IMPORTANT - need to have any here
  return useMutation<any, string>((id) => client.service(service).remove(id), {
    onSuccess: async (data) => {
      await queryCache.refetchQueries(service)
      await queryCache.setQueryData(data._id, (previous: any) => null)
    },
    onError,
    throwOnError: true
  })
}
