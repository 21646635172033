import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Row, Select, Spin, Table } from 'antd'
import { ColumnType, SortOrder } from 'antd/lib/table/interface'
import React, { FunctionComponent, HTMLAttributes, useContext } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import { onTableChange } from '../../../../components/useTable'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'
import { TaskLocation } from '../../../../providers/ClientProvider/client/services/taskLocations/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'

import './taskLocations.less'

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  dictionary: any
  t: any
  onDeleteLocation: (locationId: string) => void
}

export const Columns = (props: ColumnProps): ColumnType<Location>[] => {
  return [
    {
      title: props.t(props.dictionary.locationsPage.name),
      dataIndex: 'name'
    },
    {
      width: 3,
      // eslint-disable-next-line react/display-name
      render: (value: string, record: Location): JSX.Element => (
        <Button
          icon={<DeleteOutlined />}
          danger
          onClick={(event): void => {
            event.stopPropagation()
            props.onDeleteLocation(record._id)
          }}
        />
      )
    }
  ]
}

interface TaskLocationsViewProps {
  locations: Location[]
  locationsLoading: boolean
  availableLocations?: Location[]
  availableLocationsLoading: boolean
  setSelectedLocation: (value: string) => void
  onAddLocation: () => void
  createTaskLocationLoading: boolean
  onDeleteLocation: (locationId: string) => void
  deleteTaskLocationLoading: boolean
  taskLocations: TaskLocation[]
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Location>
  total: number
  handleOnRowClick: (id: string) => void
  setLocationSearch: (value: string) => void
}

const TaskLocationsView: FunctionComponent<TaskLocationsViewProps> = (
  props: TaskLocationsViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)

  return (
    <div className='task-locations'>
      <ContentLoader loading={props.locationsLoading}>
        <div className='task-locations__container'>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Select
                className='task-locations__select'
                showSearch
                showArrow
                placeholder={t(dictionary.general.chooseFromList)}
                filterOption={false}
                onSelect={(value) => props.setSelectedLocation(value.toString())}
                onSearch={props.setLocationSearch}
              >
                {props.availableLocationsLoading ? (
                  <Select.Option value=''>
                    <Spin size='small' />
                  </Select.Option>
                ) : (
                  props.availableLocations?.map((location: Location) => {
                    return (
                      <Select.Option key={location._id} value={location._id}>
                        {location.name}
                      </Select.Option>
                    )
                  })
                )}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Button onClick={props.onAddLocation} loading={props.createTaskLocationLoading}>
                {t(dictionary.general.add)}
              </Button>
            </Col>
          </Row>
          <Table
            rowClassName='task-locations__location-row'
            columns={Columns({
              currentSort: props.currentSort,
              t,
              dictionary,
              onDeleteLocation: props.onDeleteLocation
            })}
            loading={props.locationsLoading}
            dataSource={props.locations}
            rowKey='_id'
            onChange={props.onTableChange}
            pagination={{
              total: props.total,
              current: props.currentPage,
              showTotal: (total, range): string =>
                `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
                  dictionary.general.items
                )}`,
              showSizeChanger: false
            }}
            onRow={(record): HTMLAttributes<HTMLElement> => {
              return {
                onClick: (): void => props.handleOnRowClick(record._id)
              }
            }}
          />
        </div>
      </ContentLoader>
    </div>
  )
}

export default TaskLocationsView
