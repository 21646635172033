import { Form, message } from 'antd'
import moment from 'moment'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { Calendar } from '../../../../providers/ClientProvider/client/services/tasks/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useDeleteMutation, useFindQuery, useGetQuery, usePatchMutation } from '../../../../query'

const useTaskDetails = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const tasksPage = dictionary.tasksPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const [workerSearch, setWorkerSearch] = useState<string>('')

  const { data: workersRes } = useFindQuery('workers', {
    $limit: 10,
    $or: [
      { firstName: { $regex: workerSearch, $options: 'i' } },
      { lastName: { $regex: workerSearch, $options: 'i' } }
    ]
  })

  const { data: task } = useGetQuery('tasks', id)

  const [groupSearch, setGroupSearch] = useState<string>('')

  const { data: groupsRes } = useFindQuery('groups', {
    $limit: 10,
    name: { $regex: groupSearch, $options: 'i' }
  })

  useMemo(() => {
    if (!task || !task.calendar || !task.calendar.length) return

    const calendar = task.calendar.map((range) => ({
      ...range,
      startTime: moment().startOf('day').add(range.startTime, 'minutes'),
      endTime: moment().startOf('day').add(range.endTime, 'minutes')
    }))

    form.setFieldsValue({
      calendar: calendar
    })
  }, [task])

  const [patchTask, { status: patchStatus }] = usePatchMutation('tasks')

  const [deleteTask, { status: deleteStatus }] = useDeleteMutation('tasks')

  const onFinish = useCallback(
    async (values) => {
      if (!id) {
        throw new Error(t(tasksPage.incorrectId))
      }

      // validate workersToAssign
      if (values.workersToAssign && values.workersToAssign.length) {
        const groupIds: string[] = []

        for (let i = 0; i < values.workersToAssign.length; i++) {
          if (!values.workersToAssign[i].groupId || !values.workersToAssign[i].numberOfWorkers) {
            message.error(t(tasksPage.missingGroupValueError))
            return
          }
          if (groupIds.indexOf(values.workersToAssign[i].groupId) !== -1) {
            message.error(t(tasksPage.addGroupError))
            return
          }
          groupIds.push(values.workersToAssign[i].groupId)
        }
      }

      // validate calendar
      if (values.calendar && values.calendar.length) {
        const calendar: Calendar = values.calendar

        for (let i = 0; i < calendar.length; i++) {
          if (
            calendar[i].endDay === undefined ||
            !calendar[i].endTime ||
            calendar[i].startDay === undefined ||
            !calendar[i].startTime
          ) {
            message.error(t(dictionary.calendar.missingFieldError))
            return
          }

          if (calendar[i].endDay < calendar[i].startDay) {
            message.error(t(dictionary.calendar.errorMessage))
            return
          }

          const startDuration = moment.duration(calendar[i].startTime)
          const startMinutes = startDuration.minutes()
          let startHours = startDuration.hours()

          if (startHours === 23) {
            startHours = 0
          } else {
            startHours += 1
          }

          values.calendar[i].startTime = startMinutes + startHours * 60

          const endDuration = moment.duration(calendar[i].endTime)
          const endMinutes = endDuration.minutes()
          let endHours = endDuration.hours()

          if (endHours === 23) {
            endHours = 0
          } else {
            endHours += 1
          }

          values.calendar[i].endTime = endMinutes + endHours * 60

          if (
            calendar[i].startDay === calendar[i].endDay &&
            values.calendar[i].endTime <= values.calendar[i].startTime
          ) {
            message.error(t(dictionary.calendar.errorMessage))
            return
          }
        }
      }

      try {
        await patchTask({
          id,
          data: values
        })

        message.success(t(tasksPage.updated))
      } catch (e) {
        console.log(e)
      }
    },
    [id]
  )

  const onDelete = useCallback(async () => {
    if (!id) {
      throw new Error(t(tasksPage.incorrectId))
    }

    try {
      await deleteTask(id)

      history.push(appRoutes.tasks.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  return {
    task,
    workers: workersRes?.data || [],
    form,
    onFinish,
    patchLoading: patchStatus === 'loading',
    onDelete,
    deleteLoading: deleteStatus === 'loading',
    setGroupSearch,
    setWorkerSearch,
    groups: groupsRes?.data
  }
}
export default useTaskDetails
