// import { HookContext } from '@feathersjs/feathers'
import { mainRoutes } from '../../../../containers/Router/routes'

const handleNotAuthenticated = (history: any) => (context: any) => {
  if (context.path === 'authentication') {
    return context
  }

  if (context.error.code === 401) {
    history.push(mainRoutes.logout.path)
  }

  return context
}

export default handleNotAuthenticated
