import React, { FunctionComponent, useReducer } from 'react'

import ClientProvider from '../../providers/ClientProvider'
import { mainInitialState, mainReducer } from './context/mainReducer'
import MainContext from './context/MainContext'
import LocalizationProvider from '../../providers/LocalizationProvider'

const MainLayoutView: FunctionComponent = (props) => {
  const [state, dispatch] = useReducer(mainReducer, mainInitialState)

  return (
    <LocalizationProvider>
      <ClientProvider>
        <MainContext.Provider
          value={{
            state,
            dispatch
          }}
        >
          {props.children}
        </MainContext.Provider>
      </ClientProvider>
    </LocalizationProvider>
  )
}

export default MainLayoutView
