import React, { useContext } from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { LocalizationContext } from '../../providers/LocalizationProvider'

const NoMatchView = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const noMatchPage = dictionary.noMatchPage

  return (
    <Result
      status='404'
      title='404'
      subTitle={t(noMatchPage.sorry)}
      extra={<Link to={appRoutes.home.path}>{t(noMatchPage.return)}</Link>}
    />
  )
}

export default NoMatchView
