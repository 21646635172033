import React from 'react'

import JobsView from './JobsView'
import useJobs from './useJobs'

const Tasks = (): JSX.Element => {
  const state = useJobs()

  return <JobsView {...state} />
}

export default Tasks
