import React, { useMemo } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import routes, { NestedRoute, Route as MyRoute } from './routes'
import { Route } from 'react-router'

interface NestedRouterProps {
  routes: Record<string, MyRoute | NestedRoute>
}

function isMyRoute(route: MyRoute | NestedRoute): route is MyRoute {
  return (route as MyRoute).component !== undefined
}

const NestedRouter = (props: NestedRouterProps) => {
  const routeArray = useMemo(
    () =>
      Object.entries(props.routes)
        .map(([key, value]) => value)
        .sort((a, b) => a.order - b.order),
    []
  )

  return useMemo(() => {
    return (
      <Switch>
        {routeArray.map((route) => {
          if (isMyRoute(route)) {
            return (
              <Route
                // @ts-ignore
                key={Route} // this link is important to remove key warning, but the key must be the same for multiple elements
                exact
                path={route.path}
                children={<route.component />}
              />
            )
          }

          if (route.routes) {
            return (
              <Route
                // @ts-ignore
                key={Route} // this link is important to remove key warning, but the key must be the same for multiple elements
                exact={false}
                path={route.path}
              >
                <route.layout>
                  <NestedRouter
                    // @ts-ignore
                    key={Route} // this link is important to remove key warning, but the key must be the same for multiple elements
                    routes={route.routes}
                  />
                </route.layout>
              </Route>
            )
          }

          throw new Error('Router badly configured: missing component or routes')
        })}
      </Switch>
    )
  }, [])
}

const Router = () => {
  return (
    <BrowserRouter>
      <NestedRouter
        routes={{
          routes: routes
        }}
      />
    </BrowserRouter>
  )
}

export default Router
