import React from 'react'

import LocationCreateView from './LocationsCreateView'
import useLocationCreate from './useLocationsCreate'

const LocationCreate = (): JSX.Element => {
  const state = useLocationCreate()

  return <LocationCreateView {...state} />
}

export default LocationCreate
