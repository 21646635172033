/* eslint-disable react/display-name */
import { Table, Typography } from 'antd'
import { ColumnType, SortOrder } from 'antd/lib/table/interface'
import React, { FunctionComponent, HTMLAttributes, useContext } from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import { onTableChange } from '../../../../components/useTable'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'

import './jobLocations.less'

interface ColumnProps {
  dictionary: any
  t: any
}

export const Columns = (props: ColumnProps): ColumnType<Location>[] => {
  return [
    {
      title: props.t(props.dictionary.locationsPage.name),
      dataIndex: 'name',
      render: (value: string, record: Location): JSX.Element => (
        <Typography.Text disabled={!record.active}> {value} </Typography.Text>
      )
    }
  ]
}

interface JobLocationsViewProps {
  locations: Location[]
  locationsLoading: boolean
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Location>
  total: number
  handleOnRowClick: (id: string) => void
}

const JobLocationsView: FunctionComponent<JobLocationsViewProps> = (
  props: JobLocationsViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)

  return (
    <div className='job-locations'>
      <ContentLoader loading={props.locationsLoading}>
        <div className='job-locations__container'>
          <Table
            rowClassName='job-locations__location-row'
            columns={Columns({
              t,
              dictionary
            })}
            loading={props.locationsLoading}
            dataSource={props.locations}
            rowKey='_id'
            onChange={props.onTableChange}
            pagination={{
              total: props.total,
              current: props.currentPage,
              showTotal: (total, range): string =>
                `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
                  dictionary.general.items
                )}`,
              showSizeChanger: false
            }}
            onRow={(record): HTMLAttributes<HTMLElement> => {
              return {
                onClick: (): void => props.handleOnRowClick(record._id)
              }
            }}
          />
        </div>
      </ContentLoader>
    </div>
  )
}

export default JobLocationsView
