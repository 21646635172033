import useTable from '../../components/useTable'
import { useFindQuery } from '../../query'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes, groupShowRoutes } from '../../containers/Router/routes'
import { Group } from '../../providers/ClientProvider/client/services/groups/types'

const useGroups = () => {
  const { query, onTableChange, currentSort, currentPage } = useTable<Group>()

  const history = useHistory()

  const { data: groupsRes, status } = useFindQuery('groups', query)

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(groupShowRoutes.details.path, {
        id: id
      })
    )
  }, [])

  const onCreateNewGroup = useCallback((): void => {
    history.push(appRoutes.groupCreate.path)
  }, [])

  return {
    groups: groupsRes?.data,
    loading: status === 'loading',
    total: groupsRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleOnRowClick,
    onCreateNewGroup
  }
}
export default useGroups
