import React from 'react'

import LocationMapView from './LocationMapView'
import useLocationMap from './useLocationMap'

const LocationMap = (): JSX.Element => {
  const state = useLocationMap()

  return <LocationMapView {...state} />
}

export default LocationMap
