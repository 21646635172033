import { Spin } from 'antd'
import React from 'react'

interface Props {
  loading: boolean
  children: any
}

const ContentLoader = (props: Props): any => {
  if (props.loading) {
    return <Spin>{props.children}</Spin>
  }

  return props.children || ''
}

export default ContentLoader
