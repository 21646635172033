import { Form, message } from 'antd'
import { useCallback, useContext, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../containers/Router/routes'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { useDeleteMutation, useGetQuery, usePatchMutation } from '../../../query'

const useDeviceEdit = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const devicesPage = dictionary.devicesPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const { data: device, status } = useGetQuery('devices', id)

  const [patchDevice, { status: patchStatus }] = usePatchMutation('devices')

  const [deleteDevice, { status: deleteStatus }] = useDeleteMutation('devices')

  const onFinish = useCallback(
    async (values) => {
      if (!id) {
        throw new Error(t(devicesPage.incorrectId))
      }

      try {
        await patchDevice({
          id,
          data: values
        })

        message.success(t(devicesPage.updated))
      } catch (e) {
        console.log(e)
      }
    },
    [id]
  )

  const onDelete = useCallback(async () => {
    if (!id) {
      throw new Error(t(devicesPage.incorrectId))
    }

    try {
      await deleteDevice(id)

      history.push(appRoutes.devices.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  const firstPointLatLang = useMemo(() => {
    if (!device || !device.geoData) return

    return {
      lat:
        device.geoData[0].geometry.coordinates[
          device.geoData[0].geometry.coordinates.length - 1
        ][1],
      lng:
        device.geoData[0].geometry.coordinates[device.geoData[0].geometry.coordinates.length - 1][0]
    }
  }, [device])

  return {
    device,
    form,
    onFinish,
    patchLoading: patchStatus === 'loading',
    onDelete,
    deleteLoading: deleteStatus === 'loading',
    firstPointLatLang
  }
}
export default useDeviceEdit
