import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Popconfirm, Row, Select, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import './taskDetails.less'

import ContentLoader from '../../../../components/ContentLoader'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { Task } from '../../../../providers/ClientProvider/client/services/tasks/types'
import { Worker } from '../../../../providers/ClientProvider/client/services/workers/types'
import CalendarInput from '../../../../components/calendarInput'
import { Group } from '../../../../providers/ClientProvider/client/services/groups/types'
import TaskGroupsFormElement from '../../../../components/taskGroupsFormElement'

interface TaskDetailsViewProps {
  task?: Task
  workers: Worker[]
  form: FormInstance
  onFinish: (v: any) => void
  patchLoading: boolean
  onDelete: () => void
  deleteLoading: boolean
  setGroupSearch: (value: string) => void
  setWorkerSearch: (value: string) => void
  groups?: Group[]
}

const TaskDetailsView: FunctionComponent<TaskDetailsViewProps> = (props: TaskDetailsViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const tasksPage = dictionary.tasksPage

  return (
    <div className='edit-task'>
      <ContentLoader loading={!props.task}>
        <Form
          form={props.form}
          name='edit-task'
          onFinish={props.onFinish}
          initialValues={props.task}
          layout='vertical'
          className='app-layout__form'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Typography.Title level={4}>{t(tasksPage.taskInformation)}</Typography.Title>
              <Form.Item
                name='name'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(tasksPage.name)}
              >
                <Input placeholder={t(tasksPage.name)} type='text' />
              </Form.Item>
              <Form.Item name='client' label={t(tasksPage.client)}>
                <Input placeholder={t(tasksPage.client)} type='text' />
              </Form.Item>
              <Form.Item name='description' label={t(tasksPage.description)}>
                <Input.TextArea placeholder={t(tasksPage.description)} rows={4} />
              </Form.Item>
              <Form.Item name='contractNumber' label={t(tasksPage.contractNumber)}>
                <Input placeholder={t(tasksPage.contractNumber)} type='text' />
              </Form.Item>
              <Form.Item name='coordinator' label={t(tasksPage.coordinator)}>
                <Select
                  allowClear
                  showSearch
                  showArrow
                  placeholder={t(dictionary.general.chooseFromList)}
                  filterOption
                  optionFilterProp={'filter'}
                  onSearch={props.setWorkerSearch}
                >
                  {props.workers.map((worker) => (
                    <Select.Option
                      key={worker._id}
                      value={worker._id}
                      filter={`${worker.firstName} ${worker.lastName}`}
                    >
                      {worker.firstName} {worker.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='contactPerson' label={t(tasksPage.contactPerson)}>
                <Select
                  allowClear
                  showSearch
                  showArrow
                  placeholder={t(dictionary.general.chooseFromList)}
                  filterOption
                  optionFilterProp={'filter'}
                >
                  {props.workers.map((worker) => (
                    <Select.Option
                      key={worker._id}
                      value={worker._id}
                      filter={`${worker.firstName} ${worker.lastName}`}
                    >
                      {worker.firstName} {worker.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <TaskGroupsFormElement setGroupSearch={props.setGroupSearch} groups={props.groups} />
            </Col>
            <Col xs={24} sm={24} md={16} lg={14} xl={10}>
              <Typography.Title level={4}>{t(tasksPage.taskRepeat)}</Typography.Title>
              <CalendarInput />
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              loading={props.patchLoading}
              className='app-layout__form-button'
            >
              {t(dictionary.general.save)}
            </Button>
            <Popconfirm
              placement='left'
              title={t(tasksPage.deleteConfirmation)}
              okText={t(dictionary.general.yes)}
              cancelText={t(dictionary.general.cancel)}
              onConfirm={props.onDelete}
            >
              <Button danger loading={props.deleteLoading} className='app-layout__form-button'>
                {t(dictionary.general.delete)}
              </Button>
            </Popconfirm>
          </Row>
        </Form>
      </ContentLoader>
    </div>
  )
}

export default TaskDetailsView
