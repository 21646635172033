import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, DatePicker, Form, Row, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import './jobCreate.less'
import SubpageLayout from '../../../layouts/SubpageLayout'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { Task } from '../../../providers/ClientProvider/client/services/tasks/types'

interface JobCreateViewProps {
  form: FormInstance
  onFinish: (v: any) => void
  tasks?: Task[]
  createLoading: boolean
  setTaskSearch: (value: string) => void
}

const JobCreateView: FunctionComponent<JobCreateViewProps> = (props: JobCreateViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  return (
    <div className='create-job'>
      <SubpageLayout title={t(jobsPage.addJob)} page={t(dictionary.layouts.jobs)}>
        <Form
          form={props.form}
          name='create-job'
          onFinish={props.onFinish}
          layout='vertical'
          className='app-layout__form'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
              <Form.Item
                name='taskId'
                label={t(jobsPage.task)}
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
              >
                <Select
                  allowClear
                  showSearch
                  showArrow
                  placeholder={t(dictionary.general.chooseFromList)}
                  filterOption
                  optionFilterProp={'filter'}
                >
                  {props.tasks &&
                    props.tasks.map((task) => (
                      <Select.Option key={task._id} value={task._id} filter={`${task.name}`}>
                        {task.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='start'
                label={t(jobsPage.startDate)}
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
              >
                <DatePicker style={{ width: '100%' }} showTime />
              </Form.Item>
              <Form.Item
                name='end'
                label={t(jobsPage.endDate)}
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
              >
                <DatePicker style={{ width: '100%' }} showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              className='app-layout__form-button'
              loading={props.createLoading}
            >
              {t(jobsPage.addJob)}
            </Button>
          </Row>
        </Form>
      </SubpageLayout>
    </div>
  )
}

export default JobCreateView
