import { useCallback, useContext, useState } from 'react'
import ClientContext from '../../providers/ClientProvider/client'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { LocalizationContext } from '../../providers/LocalizationProvider'

const useLogin = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const loginPage = dictionary.loginPage

  const [saving, setSaving] = useState(false)

  const history = useHistory()

  const client = useContext(ClientContext)

  const onFinish = useCallback(
    async (values) => {
      try {
        setSaving(true)

        await client.authenticate({
          strategy: 'local',
          email: values.email,
          password: values.password
        })

        history.push(appRoutes.home.path)
      } catch (e) {
        message.error(t(loginPage.loginError))
      } finally {
        setSaving(false)
      }
    },
    [history]
  )

  return {
    saving,
    onFinish
  }
}

export default useLogin
