import React from 'react'

import HomeView from './HomeView'
import useHome from './useHome'

const Home = () => {
  const state = useHome()

  return <HomeView {...state} />
}

export default Home
