import { useCallback } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import useTable from '../../../../components/useTable'
import { appRoutes } from '../../../../containers/Router/routes'
import { Location } from '../../../../providers/ClientProvider/client/services/locations/types'
import { useFindQuery } from '../../../../query'

const useJobLocations = () => {
  const history = useHistory()

  const { id } = useParams<{ id: string }>()

  const { query, onTableChange, currentSort, currentPage } = useTable<Location>()

  const { data: jobLocationsRes, status: jobLocationsStatus } = useFindQuery('jobLocations', {
    ...query,
    jobId: id
  })

  const { data: locationsRes, status: locationsStatus } = useFindQuery(
    'locations',
    jobLocationsRes && jobLocationsRes.total
      ? {
          _id: { $in: jobLocationsRes?.data.map((jobLocation) => jobLocation.locationId) }
        }
      : false
  )

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(appRoutes.locationShow.routes.details.path, {
        id: id
      })
    )
  }, [])

  return {
    locations: locationsRes?.data || [],
    locationsLoading: jobLocationsStatus === 'loading' || locationsStatus === 'loading',
    onTableChange,
    currentSort,
    currentPage,
    total: jobLocationsRes?.total || 0,
    handleOnRowClick
  }
}
export default useJobLocations
