import qs from 'qs'
import useTable from '../../components/useTable'
import { useFindQuery } from '../../query'
import { ReactText, useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes, taskShowRoutes } from '../../containers/Router/routes'
import { Task } from '../../providers/ClientProvider/client/services/tasks/types'

const useTasks = () => {
  const { query, onTableChange, currentSort, currentPage } = useTable<Task>()

  const history = useHistory()

  const { data: tasksRes, status } = useFindQuery('tasks', query)

  const handleOnRowClick = useCallback((id: string): void => {
    history.push(
      generatePath(taskShowRoutes.details.path, {
        id: id
      })
    )
  }, [])

  const onCreateNewLocation = useCallback((): void => {
    history.push(appRoutes.taskCreate.path)
  }, [])

  const handleFilter = useCallback(
    (value: ReactText, dataIndex: string) => {
      query[dataIndex] = { $regex: value, $options: 'ix' }
      history.replace(`${history.location.pathname}?${qs.stringify(query)}`)
    },
    [history, query]
  )

  return {
    tasks: tasksRes?.data,
    loading: status === 'loading',
    total: tasksRes?.total || 0,
    onTableChange,
    currentSort,
    currentPage,
    handleOnRowClick,
    onCreateNewLocation,
    handleFilter
  }
}
export default useTasks
