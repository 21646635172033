import React from 'react'

import JobDetailsView from './JobDetailsView'
import useLocationDetails from './useJobDetails'

const JobDetails = (): JSX.Element => {
  const state = useLocationDetails()

  return <JobDetailsView {...state} />
}

export default JobDetails
