import { Form } from 'antd'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes } from '../../../containers/Router/routes'
import { useCreateMutation } from '../../../query'

const useDeviceCreate = () => {
  const [form] = Form.useForm()

  const history = useHistory()

  const [createDevice, { status: createStatus }] = useCreateMutation('devices')

  const onFinish = useCallback(async () => {
    try {
      const values = await form.validateFields()
      const user = await createDevice({
        ...values
      })

      history.replace(
        generatePath(appRoutes.deviceEdit.path, {
          id: user._id
        })
      )
    } catch (e) {
      console.log(e)
    }
  }, [form])

  return {
    form,
    onFinish,
    createLoading: createStatus === 'loading'
  }
}
export default useDeviceCreate
