import { Form } from 'antd'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes } from '../../../containers/Router/routes'
import { useCreateMutation } from '../../../query'

const useWorkerCreate = () => {
  const [form] = Form.useForm()

  const history = useHistory()

  const [createWorker, { status: createStatus }] = useCreateMutation('workers')

  const onFinish = useCallback(async () => {
    try {
      const values = await form.validateFields()

      const worker = await createWorker({
        ...values
      })

      history.replace(
        generatePath(appRoutes.workerShow.routes.data.path, {
          id: worker._id
        })
      )
    } catch (e) {
      console.log(e)
    }
  }, [form])

  return {
    form,
    onFinish,
    createLoading: createStatus === 'loading'
  }
}
export default useWorkerCreate
