import React from 'react'

import GroupsView from './GroupsView'
import useGroups from './useGroups'

const Groups = (): JSX.Element => {
  const state = useGroups()

  return <GroupsView {...state} />
}

export default Groups
