import React, { FunctionComponent } from 'react'

import TaskShowLayoutView from './TaskShowLayoutView'
import useTaskShowLayout from './useTaskShowLayout'

interface TaskShowLayoutProps {
  children?: React.ReactNode
}

const TaskShowLayout: FunctionComponent<TaskShowLayoutProps> = (props: TaskShowLayoutProps) => {
  const listLayoutState = useTaskShowLayout()

  return <TaskShowLayoutView {...props} {...listLayoutState} />
}

export default TaskShowLayout
