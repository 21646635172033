import React from 'react'

import WorkerDataView from './WorkerDataView'
import useWorkerData from './useWorkerData'

const WorkerData = (): JSX.Element => {
  const state = useWorkerData()

  return <WorkerDataView {...state} />
}

export default WorkerData
