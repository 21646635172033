import { Form, message } from 'antd'
import moment from 'moment'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../containers/Router/routes'
import { JobStatus } from '../../../../providers/ClientProvider/client/services/jobs/JobStatus.enum'
import { LocalizationContext } from '../../../../providers/LocalizationProvider'
import { useFindQuery, useGetQuery, usePatchMutation } from '../../../../query'

const useJobDetails = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  const { id } = useParams<{ id: string }>()

  const [form] = Form.useForm()

  const history = useHistory()

  const { data: job } = useGetQuery('jobs', id)

  const [workerSearch, setWorkerSearch] = useState<string>('')

  const { data: workersRes } = useFindQuery('workers', {
    $limit: 10,
    $or: [
      { firstName: { $regex: workerSearch, $options: 'i' } },
      { lastName: { $regex: workerSearch, $options: 'i' } }
    ]
  })

  const [patchJob, { status: patchStatus }] = usePatchMutation('jobs')

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  useMemo(() => {
    if (!job) return
    setStartDate(job.start)
    setEndDate(job.end)
  }, [job])

  const jobDuration = useMemo(() => {
    if (!job) return

    const duration = moment.duration(moment(job.end).diff(job.start))

    return `${duration.hours()} ${t(jobsPage.hours)} ${duration.minutes()} ${t(jobsPage.minutes)}`
  }, [job])

  const onFinish = useCallback(
    async (values) => {
      if (!id) {
        throw new Error(t(jobsPage.incorrectId))
      }

      try {
        await patchJob({
          id,
          data: values
        })

        message.success(t(jobsPage.updated))
      } catch (e) {
        console.log(e)
      }
    },
    [id]
  )

  const onArchive = useCallback(async () => {
    if (!id) {
      throw new Error(t(jobsPage.incorrectId))
    }

    try {
      await patchJob({
        id,
        data: {
          status: JobStatus.Archived
        }
      })

      history.push(appRoutes.jobs.path)
    } catch (e) {
      console.log(e)
    }
  }, [id])

  return {
    job,
    form,
    onFinish,
    patchLoading: patchStatus === 'loading',
    onArchive,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    jobDuration,
    workers: workersRes?.data,
    setWorkerSearch
  }
}
export default useJobDetails
