import React, { FunctionComponent } from 'react'

import JobShowLayoutView from './JobShowLayoutView'
import useJobShowLayout from './useJobShowLayout'

interface JobShowLayoutProps {
  children?: React.ReactNode
}

const JobShowLayout: FunctionComponent<JobShowLayoutProps> = (props: JobShowLayoutProps) => {
  const listLayoutState = useJobShowLayout()

  return <JobShowLayoutView {...props} {...listLayoutState} />
}

export default JobShowLayout
