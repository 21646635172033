import React from 'react'

import TaskDetailsView from './TaskDetailsView'
import useLocationDetails from './useTaskDetails'

const TaskDetails = (): JSX.Element => {
  const state = useLocationDetails()

  return <TaskDetailsView {...state} />
}

export default TaskDetails
