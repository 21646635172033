import { Menu } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { appRoutes } from '../../containers/Router/routes'
import ContentLoader from '../../components/ContentLoader'
import SubpageLayout from '../SubpageLayout'

import './locationShowLayout.less'
import { Location } from '../../providers/ClientProvider/client/services/locations/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'

interface LocationShowLayoutViewProps {
  children?: React.ReactNode
  location?: Location
  menuSelectedKey?: string
  id: string
}

const LocationShowLayoutView: FunctionComponent<LocationShowLayoutViewProps> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const layouts = dictionary.layouts

  return (
    <ContentLoader loading={!props.location}>
      <SubpageLayout
        title={props.location ? `${props.location?.name}` : ''}
        page={t(layouts.locations)}
      >
        <Menu
          mode='horizontal'
          selectedKeys={props.menuSelectedKey ? [props.menuSelectedKey] : undefined}
        >
          <Menu.Item key={appRoutes.locationShow.routes.details.path}>
            <Link
              to={generatePath(appRoutes.locationShow.routes.details.path, { id: props.id })}
              replace
            >
              {t(layouts.details)}
            </Link>
          </Menu.Item>
          <Menu.Item key={appRoutes.locationShow.routes.map.path}>
            <Link
              to={generatePath(appRoutes.locationShow.routes.map.path, { id: props.id })}
              replace
            >
              {t(layouts.map)}
            </Link>
          </Menu.Item>
        </Menu>
        <div className='worker-show-layout'>{props.children}</div>
      </SubpageLayout>
    </ContentLoader>
  )
}

export default LocationShowLayoutView
