import { Button, Col, Form, Row, Select, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React, { FunctionComponent, useContext } from 'react'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'
import { Worker } from '../../providers/ClientProvider/client/services/workers/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'

interface HomeViewProps {
  formAssign: FormInstance
  formTurnOver: FormInstance
  onFinishAssign: (v: any) => void
  onFinishTurnOver: (v: any) => void
  createLoading: boolean
  turnOverLoading: boolean
  workers?: Worker[]
  workersLoading: boolean
  devices?: Device[]
  devicesToTurnOver?: Device[]
  setWorkerSearch: (value: string) => void
  setDeviceSearch: (value: string) => void
  setDeviceToTurnOverSearch: (value: string) => void
}

const HomeView: FunctionComponent<HomeViewProps> = (props: HomeViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)

  return (
    <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
      <Col xs={24} sm={24} md={10} lg={8} xl={6}>
        <Typography.Title level={4}>{t(dictionary.general.assign)}</Typography.Title>
        <Form
          form={props.formAssign}
          name='assign-device'
          onFinish={props.onFinishAssign}
          layout='vertical'
        >
          <Form.Item
            name='workerId'
            rules={[{ required: true, message: t(dictionary.general.formError) }]}
            label={t(dictionary.general.worker)}
          >
            <Select
              showSearch
              showArrow
              placeholder={t(dictionary.general.chooseFromList)}
              filterOption={false}
              onSearch={props.setWorkerSearch}
            >
              {props.workers?.map((worker) => (
                <Select.Option key={worker._id} value={worker._id}>
                  {worker.firstName} {worker.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='deviceId'
            rules={[{ required: true, message: t(dictionary.general.formError) }]}
            label={t(dictionary.general.device)}
          >
            <Select
              showSearch
              showArrow
              placeholder={'IMEI'}
              filterOption={false}
              onSearch={props.setDeviceSearch}
            >
              {props.devices?.map((device: Device) => (
                <Select.Option key={device._id} value={device._id}>
                  {device.imei}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='app-layout__form-button'
            loading={props.createLoading}
          >
            {t(dictionary.general.assign)}
          </Button>
        </Form>
      </Col>
      <Col xs={24} sm={24} md={10} lg={8} xl={6}>
        <Typography.Title level={4}>{t(dictionary.general.turnOver)}</Typography.Title>
        <Form
          form={props.formTurnOver}
          name='turn-over-device'
          onFinish={props.onFinishTurnOver}
          layout='vertical'
        >
          <Form.Item
            name='deviceId'
            rules={[{ required: true, message: t(dictionary.general.formError) }]}
            label={t(dictionary.general.device)}
          >
            <Select
              showSearch
              showArrow
              placeholder={'IMEI'}
              filterOption={false}
              onSearch={props.setDeviceToTurnOverSearch}
            >
              {props.devicesToTurnOver?.map((device: Device) => (
                <Select.Option key={device._id} value={device._id}>
                  {device.imei}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='app-layout__form-button'
            loading={props.turnOverLoading}
          >
            {t(dictionary.general.turnOver)}
          </Button>
        </Form>
      </Col>
    </Row>
  )
}

export default HomeView
