import React, { FunctionComponent, useContext } from 'react'
import { LocalizationContext } from '../../providers/LocalizationProvider'

const LogOutView: FunctionComponent = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const logoutPage = dictionary.logoutPage

  return <div>{t(logoutPage.loginOut)}</div>
}

export default LogOutView
