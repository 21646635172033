import feathers from '@feathersjs/client'
import auth from '@feathersjs/authentication-client'
import { createContext } from 'react'
import { Application } from '@feathersjs/feathers'

import appHooks from './app.hooks'
import { CRUDServiceType } from './services/CRUDService'
import config from '../../../config'

import { User } from './services/users/types'
import { Worker } from './services/workers/types'
import { Device } from './services/devices/types'
import { WorkerDevice } from './services/workerDevices/types'
import { Location } from './services/locations/types'
import { Task } from './services/tasks/types'
import { WorkerGroup } from './services/workerGroups/types'
import { Group } from './services/groups/types'
import { TaskLocation } from './services/taskLocations/types'
import { Job } from './services/jobs/types'
import { JobLocation } from './services/jobLocations/types'
import { JobWorker } from './services/jobWorkers/types'

// A mapping of service names to types. Will be extended in service files.
export interface ServiceModels {
  users: User
  workers: Worker
  devices: Device
  workerDevices: WorkerDevice
  locations: Location
  tasks: Task
  workerGroups: WorkerGroup
  groups: Group
  taskLocations: TaskLocation
  jobs: Job
  jobLocations: JobLocation
  jobWorkers: JobWorker
}
type ServiceTypes = CRUDServiceType<ServiceModels>

// The application instance type that will be used everywhere else
export type IClient = Application<ServiceTypes>

const client: IClient = feathers()

// Connect to a different URL
const restClient = feathers.rest(config.apiURL)

// Configure an AJAX library (see below) with that client
client.configure(restClient.fetch(window.fetch))
client.configure(
  auth({
    storageKey: config.jwtStorageKey,
    path: '/authentication'
  })
)

const ClientContext = createContext<IClient>(client)

let clientInitialized = false

function initClient(history: any): IClient {
  // React.StrictMode causes components to render twice at the start
  // because of this we need to secure client init with clientInitialized
  // so the hooks do not get set up twice
  if (!clientInitialized) {
    client.hooks(appHooks(history))
    clientInitialized = true
  }

  return client
}

export default ClientContext
export { initClient }
