import { Menu } from 'antd'
import React, { FunctionComponent, useContext } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { taskShowRoutes } from '../../containers/Router/routes'
import ContentLoader from '../../components/ContentLoader'
import SubpageLayout from '../SubpageLayout'

import './taskShowLayout.less'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { Task } from '../../providers/ClientProvider/client/services/tasks/types'

interface TaskShowLayoutViewProps {
  children?: React.ReactNode
  task?: Task
  menuSelectedKey?: string
  id: string
}

const TaskShowLayoutView: FunctionComponent<TaskShowLayoutViewProps> = (props) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const layouts = dictionary.layouts

  return (
    <ContentLoader loading={!props.task}>
      <SubpageLayout title={props.task ? `${props.task?.name}` : ''} page={t(layouts.tasks)}>
        <Menu
          mode='horizontal'
          selectedKeys={props.menuSelectedKey ? [props.menuSelectedKey] : undefined}
        >
          <Menu.Item key={taskShowRoutes.details.path}>
            <Link to={generatePath(taskShowRoutes.details.path, { id: props.id })} replace>
              {t(layouts.details)}
            </Link>
          </Menu.Item>
          <Menu.Item key={taskShowRoutes.locations.path}>
            <Link to={generatePath(taskShowRoutes.locations.path, { id: props.id })} replace>
              {t(layouts.locations)}
            </Link>
          </Menu.Item>
          <Menu.Item key={taskShowRoutes.map.path}>
            <Link to={generatePath(taskShowRoutes.map.path, { id: props.id })} replace>
              {t(layouts.map)}
            </Link>
          </Menu.Item>
        </Menu>
        <div className='task-show-layout'>{props.children}</div>
      </SubpageLayout>
    </ContentLoader>
  )
}

export default TaskShowLayoutView
