import { Form, message } from 'antd'
import { useCallback, useContext, useState } from 'react'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { useCreateMutation, useFindQuery, usePatchMutation } from '../../query'
import { queryCache } from 'react-query'

const useHome = () => {
  const { t, dictionary } = useContext(LocalizationContext)
  const homePage = dictionary.homePage

  const [formAssign] = Form.useForm()
  const [formTurnOver] = Form.useForm()

  const [createWorkerDevice, { status: createStatus }] = useCreateMutation('workerDevices')

  const [patchWorkerDevice, { status: patchStatus }] = usePatchMutation('workerDevices')

  const { data: workerDeviceToPatchRes } = useFindQuery('workerDevices', {
    $limit: 1,
    deviceId: formTurnOver.getFieldValue('deviceId'),
    returnedDate: null
  })

  const [deviceToTurnOverSearch, setDeviceToTurnOverSearch] = useState<string>('')

  const { data: devicesToTurnOverRes } = useFindQuery('devices', {
    $limit: 10,
    imei: { $regex: deviceToTurnOverSearch, $options: 'i' }
  })

  const [workerSearch, setWorkerSearch] = useState<string>('')

  const { data: workersRes, status: workersStatus } = useFindQuery('workers', {
    $limit: 10,
    $or: [
      { firstName: { $regex: workerSearch, $options: 'i' } },
      { lastName: { $regex: workerSearch, $options: 'i' } }
    ]
  })

  const [deviceSearch, setDeviceSearch] = useState<string>('')

  const { data: devicesRes } = useFindQuery('devices', {
    $limit: 10,
    imei: { $regex: deviceSearch, $options: 'i' }
  })

  const onFinishAssign = useCallback(async () => {
    try {
      const values = await formAssign.validateFields()

      await createWorkerDevice({
        ...values,
        assignedDate: new Date()
      })

      formAssign.resetFields()

      setWorkerSearch('')
      setDeviceSearch('')

      message.success(t(homePage.assignDevice))

      await queryCache.refetchQueries('workers')
      await queryCache.refetchQueries('devices')
    } catch (e) {
      console.log(e)
    }
  }, [formAssign])

  const onFinishTurnOver = useCallback(async () => {
    try {
      if (!workerDeviceToPatchRes?.data[0]) {
        message.error(t(homePage.turnOverError))
        return
      }

      await patchWorkerDevice({
        id: workerDeviceToPatchRes?.data[0]._id,
        data: {
          returnedDate: new Date()
        }
      })

      formTurnOver.resetFields()
      setDeviceToTurnOverSearch('')

      message.success(t(homePage.turnOver))

      await queryCache.refetchQueries('workers')
      await queryCache.refetchQueries('devices')
    } catch (e) {
      console.log(e)
    }
  }, [workerDeviceToPatchRes])

  return {
    formAssign,
    formTurnOver,
    onFinishAssign,
    onFinishTurnOver,
    createLoading: createStatus === 'loading',
    turnOverLoading: patchStatus === 'loading',
    workers: workersRes?.data.filter((worker) => !worker.deviceId),
    workersLoading: workersStatus === 'loading',
    devices: devicesRes?.data.filter((device) => !device.workerId),
    devicesToTurnOver: devicesToTurnOverRes?.data.filter((device) => device.workerId),
    setWorkerSearch,
    setDeviceSearch,
    setDeviceToTurnOverSearch
  }
}
export default useHome
