import React from 'react'

import JobLocationsView from './JobLocationsView'
import useJobLocations from './useJobLocations'

const JobLocations = (): JSX.Element => {
  const state = useJobLocations()

  return <JobLocationsView {...state} />
}

export default JobLocations
