import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, InputNumber, Row, Select, Typography } from 'antd'
import React, { useContext } from 'react'
import { Group } from '../../providers/ClientProvider/client/services/groups/types'
import { LocalizationContext } from '../../providers/LocalizationProvider'

import './taskGroupsFormElement.less'

interface Props {
  setGroupSearch: (value: string) => void
  groups?: Group[]
}

const TaskGroupsFormElement = (props: Props): any => {
  const { t, dictionary } = useContext(LocalizationContext)
  const tasksPage = dictionary.tasksPage

  return (
    <Form.List name='workersToAssign'>
      {(fields, { add, remove }) => {
        return (
          <div className='task-group-form-element'>
            <Typography.Title level={4}>{t(tasksPage.workersToAssign)}</Typography.Title>
            {fields.map((field, index) => (
              <Row key={`groups_${index}`} gutter={8}>
                <Col span={16}>
                  <Form.Item
                    {...field}
                    key={`${field.key}_groupId`}
                    name={[field.name, 'groupId']}
                    fieldKey={[field.fieldKey, 'groupId']}
                    required={true}
                  >
                    <Select
                      showSearch
                      showArrow
                      placeholder={t(dictionary.general.chooseFromList)}
                      filterOption={false}
                      onSearch={props.setGroupSearch}
                    >
                      {props.groups &&
                        props.groups.map((group: Group) => {
                          return (
                            <Select.Option key={group._id} value={group._id}>
                              {group.name}
                            </Select.Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...field}
                    key={`${field.key}_numberOfWorkers`}
                    name={[field.name, 'numberOfWorkers']}
                    fieldKey={[field.fieldKey, 'numberOfWorkers']}
                    required={true}
                  >
                    <InputNumber
                      min={1}
                      placeholder={t(tasksPage.numberOfWorkers)}
                      className='task-group-form-element__number-Input'
                    />
                  </Form.Item>
                </Col>
                <Col span={2} className='task-group-form-element__remove-group-column'>
                  <Button
                    shape='circle'
                    icon={<MinusOutlined />}
                    onClick={() => remove(field.name)}
                    size='small'
                  />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type='dashed'
                onClick={() => {
                  add()
                  props.setGroupSearch('')
                }}
                style={{ width: '100%' }}
              >
                <PlusOutlined /> {t(tasksPage.addGroup)}
              </Button>
            </Form.Item>
          </div>
        )
      }}
    </Form.List>
  )
}

export default TaskGroupsFormElement
