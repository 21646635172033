import React from 'react'

import LocationDetailsView from './LocationDetailsView'
import useLocationDetails from './useLocationDetails'

const LocationDetails = (): JSX.Element => {
  const state = useLocationDetails()

  return <LocationDetailsView {...state} />
}

export default LocationDetails
