import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Row, Switch } from 'antd'
import { FormInstance } from 'antd/lib/form'
import './locationsCreate.less'
import SubpageLayout from '../../../layouts/SubpageLayout'
import { LocalizationContext } from '../../../providers/LocalizationProvider'

interface LocationCreateViewProps {
  form: FormInstance
  onFinish: (v: any) => void
  createLoading: boolean
}

const LocationCreateView: FunctionComponent<LocationCreateViewProps> = (
  props: LocationCreateViewProps
) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const locationsPage = dictionary.locationsPage

  return (
    <div className='create-location'>
      <SubpageLayout title={t(locationsPage.addLocation)} page={t(dictionary.layouts.locations)}>
        <Form
          form={props.form}
          name='create-location'
          onFinish={props.onFinish}
          layout='vertical'
          className='app-layout__form'
        >
          <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: t(dictionary.general.formError) }]}
                label={t(locationsPage.name)}
              >
                <Input placeholder={t(locationsPage.name)} type='text' />
              </Form.Item>
              <Form.Item
                initialValue={true}
                name='active'
                label={t(locationsPage.active)}
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row className='app-layout__last-row'>
            <Button
              type='primary'
              htmlType='submit'
              className='app-layout__form-button'
              loading={props.createLoading}
            >
              {t(locationsPage.createLocation)}
            </Button>
          </Row>
        </Form>
      </SubpageLayout>
    </div>
  )
}

export default LocationCreateView
