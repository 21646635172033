import React, { FunctionComponent, useEffect } from 'react'
import L, { MapOptions } from 'leaflet'
import 'leaflet-arrowheads'

import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

import './map.less'

import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { coordinates, GeoData, GeomanMap, LatLng } from './mapType'
import { shapeType } from './geomanShape.enum'

interface MapWithGeomanProps extends MapOptions {
  setMap?: (map: GeomanMap) => void
  geoData?: GeoData[]
  centeredLatLng?: LatLng | coordinates
}

const MapWithGeoman: FunctionComponent<MapWithGeomanProps> = (props: MapWithGeomanProps) => {
  const map: GeomanMap = useMap() as GeomanMap

  useEffect(() => {
    if (!map) return

    if (props.setMap) {
      props.setMap(map)

      map.pm.addControls({
        position: 'topleft',
        drawMarker: false,
        drawCircleMarker: false,
        drawCircle: false
      })
    }

    if (!props.geoData || !props.geoData.length) return

    props.geoData.map((geoData) => {
      let layer: any
      if (geoData.geometry.type === shapeType.LineString) {
        layer = (L.polyline(
          (geoData.geometry.coordinates as coordinates[]).map((coordinates) => [
            coordinates[1],
            coordinates[0]
          ])
        ) as any)
          .arrowheads({ frequency: '120px', size: '12px' })
          .addTo(map)
      } else {
        layer = L.geoJSON(geoData).addTo(map)
      }
      layer.on('mouseover', function () {
        layer.setStyle({
          color: 'blue'
        })
      })
      layer.on('mouseout', function () {
        layer.setStyle({
          color: 'rgb(51, 136, 255)'
        })
      })
    })
  }, [map])

  return (
    <map>
      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
    </map>
  )
}

const Map: FunctionComponent<MapWithGeomanProps> = (props: MapWithGeomanProps) => {
  return (
    <MapContainer
      center={props.centeredLatLng ? props.centeredLatLng : [52.221113, 21.012174]}
      zoom={14}
      scrollWheelZoom={false}
      className='map'
    >
      <MapWithGeoman {...props} />
    </MapContainer>
  )
}

export default Map
