/* eslint-disable react/display-name */
import React, { FunctionComponent, HTMLAttributes, ReactText, useContext } from 'react'
import { Spin, Table, Typography } from 'antd'

import { SortOrder } from 'antd/lib/table/interface'
import { ColumnType } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'

import ListLayout from '../../layouts/ListLayout'
import { onTableChange } from '../../components/useTable'
import FilterTextSearch from '../../components/FilterTextSearch'
import FilterTags from '../../components/filterTags'
import { LocalizationContext } from '../../providers/LocalizationProvider'
import { Job } from '../../providers/ClientProvider/client/services/jobs/types'
import { getSelectableFilterColumn } from '../../components/getSelectableFilterColumn'
import { JobStatus } from '../../providers/ClientProvider/client/services/jobs/JobStatus.enum'
import { useFindQuery } from '../../query'
import moment from 'moment'

const JobWorkers = (props: { job: Job }): JSX.Element => {
  let allRequiredWorkers = 0

  props.job.workersToAssign?.map((group) => {
    allRequiredWorkers += group.numberOfWorkers
  })

  const { data: jobWorkersRes, status: findJobWorkersStatus } = useFindQuery('jobWorkers', {
    $limit: 0,
    jobId: props.job._id,
    active: true
  })

  if (findJobWorkersStatus === 'loading') {
    return <Spin size='small' />
  }

  return (
    <>
      <Typography.Text
        type={jobWorkersRes && jobWorkersRes?.total < allRequiredWorkers ? 'danger' : undefined}
      >
        {jobWorkersRes?.total} / {allRequiredWorkers}
      </Typography.Text>
    </>
  )
}

const jobStatus = Object.values(JobStatus).map((jobStatus) => ({
  label: jobStatus,
  value: jobStatus
}))

interface ColumnProps {
  currentSort: { [key: string]: SortOrder }
  handleFilter: (value: ReactText, dataIndex: string) => void
  jobsPage: any
  t: any
}

export const Columns = (props: ColumnProps): ColumnType<Job>[] => {
  return [
    {
      title: props.t(props.jobsPage.name),
      dataIndex: 'name',
      sorter: true,
      sortOrder: props?.currentSort['name'],
      filterIcon: <SearchOutlined />,
      filterDropdown: ({ setSelectedKeys, selectedKeys }): JSX.Element =>
        FilterTextSearch({
          setSelectedKeys: setSelectedKeys,
          selectedKeys: selectedKeys,
          handleFilter: props.handleFilter,
          dataIndex: 'name'
        })
    },
    {
      title: props.t(props.jobsPage.status),
      dataIndex: 'status',
      sorter: true,
      sortOrder: props?.currentSort['status'],
      ...getSelectableFilterColumn('status', props.handleFilter, jobStatus)
    },
    {
      title: props.t(props.jobsPage.workers),
      render: (value: string, record: Job): JSX.Element => <JobWorkers job={record} />
    },
    {
      title: props.t(props.jobsPage.startDate),
      dataIndex: 'start',
      sorter: true,
      sortOrder: props?.currentSort['start'],
      responsive: ['md'],
      render: (value: Date): any => moment(value).format('HH:mm D-M-YYYY')
    },
    {
      title: props.t(props.jobsPage.endDate),
      dataIndex: 'end',
      sorter: true,
      sortOrder: props?.currentSort['end'],
      responsive: ['md'],
      render: (value: Date): any => moment(value).format('HH:mm D-M-YYYY')
    }
  ]
}

interface JobsViewProps {
  jobs?: Job[]
  loading: boolean
  total: number
  currentPage: number
  currentSort: { [key: string]: SortOrder }
  onTableChange: onTableChange<Job>
  handleOnRowClick: (id: string) => void
  onCreateNewLocation: () => void
  handleFilter: (value: ReactText, dataIndex: string) => void
}

const JobsView: FunctionComponent<JobsViewProps> = (props: JobsViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const jobsPage = dictionary.jobsPage

  return (
    <ListLayout
      title={t(dictionary.layouts.jobs)}
      buttonAction={props.onCreateNewLocation}
      buttonText={t(jobsPage.addJob)}
    >
      <FilterTags />
      <Table
        rowClassName='list-layout__list-row'
        columns={Columns({
          currentSort: props.currentSort,
          handleFilter: props.handleFilter,
          t: t,
          jobsPage
        })}
        loading={props.loading}
        dataSource={props.jobs}
        rowKey='_id'
        onChange={props.onTableChange}
        pagination={{
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range): string =>
            `${range[0]}-${range[1]} ${t(dictionary.general.of)} ${total} ${t(
              dictionary.general.items
            )}`,
          showSizeChanger: false
        }}
        onRow={(record): HTMLAttributes<HTMLElement> => {
          return {
            onClick: (): void => props.handleOnRowClick(record._id)
          }
        }}
      />
    </ListLayout>
  )
}

export default JobsView
