import React, { FunctionComponent, useContext } from 'react'
import { Button, Col, Form, Input, Popconfirm, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'

import './userEdit.less'

import { User } from '../../../providers/ClientProvider/client/services/users/types'
import ContentLoader from '../../../components/ContentLoader'
import SubpageLayout from '../../../layouts/SubpageLayout'
import { LocalizationContext } from '../../../providers/LocalizationProvider'

interface UserEditViewProps {
  user?: User
  form: FormInstance
  onFinish: (v: any) => void
  patchLoading: boolean
  onDelete: () => void
  deleteLoading: boolean
}

const UserEditView: FunctionComponent<UserEditViewProps> = (props: UserEditViewProps) => {
  const { t, dictionary } = useContext(LocalizationContext)
  const usersPage = dictionary.usersPage

  return (
    <div className='edit-user'>
      <SubpageLayout title={t(usersPage.userDetails)} page={t(dictionary.layouts.users)}>
        <ContentLoader loading={!props.user}>
          <Form
            form={props.form}
            name='edit-user'
            onFinish={props.onFinish}
            initialValues={props.user}
            className='app-layout__form'
            layout='vertical'
          >
            <Row gutter={[16, 32]} className='app-layout__columns-arrangement'>
              <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                <Form.Item
                  name='email'
                  label='Email'
                  rules={[{ required: true, message: t(dictionary.general.formError) }]}
                >
                  <Input placeholder='Email' type='text' />
                </Form.Item>
                <Form.Item
                  name='password'
                  label={t(usersPage.password)}
                  rules={[{ required: true, message: t(dictionary.general.formError) }]}
                >
                  <Input placeholder={t(usersPage.password)} type='password' />
                </Form.Item>
              </Col>
            </Row>
            <Row className='app-layout__last-row'>
              <Button
                type='primary'
                htmlType='submit'
                loading={props.patchLoading}
                className='app-layout__form-button'
              >
                {t(dictionary.general.save)}
              </Button>
              <Popconfirm
                placement='left'
                title={t(usersPage.deleteConfirmation)}
                okText={t(dictionary.general.yes)}
                cancelText={t(dictionary.general.cancel)}
                onConfirm={props.onDelete}
              >
                <Button danger loading={props.deleteLoading} className='app-layout__form-button'>
                  {t(dictionary.general.delete)}
                </Button>
              </Popconfirm>
            </Row>
          </Form>
        </ContentLoader>
      </SubpageLayout>
    </div>
  )
}

export default UserEditView
