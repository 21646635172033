import React, { FunctionComponent } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { Device } from '../../providers/ClientProvider/client/services/devices/types'

interface WorkerDeviceViewProps {
  device?: Device
}

const WorkerDeviceView: FunctionComponent<WorkerDeviceViewProps> = (
  props: WorkerDeviceViewProps
) => {
  return (
    <>
      {props.device && (
        <Link
          to={generatePath(appRoutes.deviceEdit.path, {
            id: props.device?._id
          })}
          onClick={(event) => event.stopPropagation()}
        >
          {props.device.imei}
        </Link>
      )}
    </>
  )
}

export default WorkerDeviceView
