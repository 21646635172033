// Application hooks that run for every service
// Don't remove this comment. It's needed to format import lines nicely.

import handleNotAuthenticated from './hooks/handleNotAuthenticated'

export default (history: any) => ({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },

  error: {
    all: [handleNotAuthenticated(history)],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})
