import React from 'react'

import UserEditView from './UserEditView'
import useUserEdit from './useUserEdit'

const UserEdit = (): JSX.Element => {
  const state = useUserEdit()

  return <UserEditView {...state} />
}

export default UserEdit
