interface Config {
  jwtStorageKey: string
  apiURL: string
}

let config: Config = {
  jwtStorageKey: 'auth',
  apiURL: 'missing'
}

const initConfig = () => {
  if (!process.env.REACT_APP_API_URL) {
    throw new Error('REACT_APP_API_URL not specified')
  }
  if (!process.env.REACT_APP_JWT_STORAGE_KEY) {
    throw new Error('REACT_APP_JWT_STORAGE_KEY not specified')
  }

  config = {
    ...config,
    jwtStorageKey: process.env.REACT_APP_JWT_STORAGE_KEY,
    apiURL: process.env.REACT_APP_API_URL
  }
}

initConfig()

export default config
