import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space } from 'antd'
import React, { ReactText } from 'react'

interface FilterTextSearchProps {
  handleFilter: (value: ReactText, dataIndex: string) => void
  selectedKeys: ReactText[]
  setSelectedKeys: (e: any) => void
  dataIndex: string
}

const FilterTextSearch = (props: FilterTextSearchProps): JSX.Element => (
  <div style={{ padding: 8 }}>
    <Space>
      <Input
        allowClear
        placeholder={`Wpisz szukany tekst`}
        value={props.selectedKeys[0]}
        onChange={(e): void => props.setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={(): void => props.handleFilter(props.selectedKeys[0], props.dataIndex)}
        style={{ width: 'auto', height: '30px' }}
      />
      <Button
        type='primary'
        onClick={(): void => props.handleFilter(props.selectedKeys[0], props.dataIndex)}
        icon={<SearchOutlined />}
        size='small'
        style={{ width: '30px', height: '30px' }}
      />
    </Space>
  </div>
)

export default FilterTextSearch
